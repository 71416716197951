import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";

const columns = [
  { field: "date", headerName: "Date", width: 100 },
  {
    field: "customerName",
    headerName: "Customer Name",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", padding: "4px 0px" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "dispatchedTo",
    headerName: "Dispatched To",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", padding: "4px 0px" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "salesRep",
    headerName: "Salesrep",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", padding: "4px 0px" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "leadType",
    headerName: "Lead Type",
    sortable: false,
    width: 90,
  },
  {
    field: "jobDuration",
    headerName: "Time on Job",
    width: 90,
    valueFormatter: (params) => {
      let formattedDuration = "";
      if (params.value < 60) {
        formattedDuration = `${params.value} min`;
      } else {
        const hours = Math.floor(params.value / 60);
        const minutes = params.value % 60;
        formattedDuration = `${hours}h ${minutes}m`;
      }
      return formattedDuration;
    },
    // sortComparator: (v1, v2, cellParams1, cellParams2) => {
    //   return v1 - v2;
    // },
  },
  {
    field: "reviewRating",
    headerName: "Review",
    width: 70,
    editable: true,
  },

  {
    field: "totalAmount",
    headerName: "Job Total",
    width: 110,
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  {
    field: "paid",
    headerName: "Paid",
    width: 70,
    editable: true,
  },
  {
    field: "paymentType",
    headerName: "Payment Type",
    width: 110,
    editable: true,
  },

  {
    field: "tip",
    headerName: "Tip",
    width: 90,
    editable: true,
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  {
    field: "tax",
    headerName: "Tax",
    width: 90,
    editable: true,
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  {
    field: "totalPaid",
    headerName: "Total Paid",
    width: 110,
    editable: true,
    align: "right",
    valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
  {
    field: "note",
    headerName: "Notes",
    width: 250,
    editable: false,
    // align: "right",
    // valueFormatter: (params) => `$${params.value.toFixed(2)}`,
  },
];

const sxOverwrite = {
  "& .Mui-checked .MuiSvgIcon-root": {
    color: "#111827", // Change to your desired color
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#fefce8", // This is a light red. Change this to your desired color.
  },
  "& .MuiDataGrid-row.Mui-selected:hover": {
    backgroundColor: "#fef9c3", // This is a yellow color for the hover effect. Adjust as needed.
  },
  "& .MuiDataGrid-cell:focus": {
    // border: "1px solid #111827 ", // This gives a yellow border to the focused cell.
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-cellCheckbox:focus-within": {
    // border: "1px solid #111827", // This targets the checkbox cell specifically when focused.
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    // border: "1px solid #111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    // border: "1px solid #111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon": {
    color: "#111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox:focus-within": {
    // border: "1px solid #111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .Mui-checked .MuiSvgIcon-root": {
    // color: "#111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiIconButton-root:focus-visible": {
    // color: "#111827",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiTouchRipple-root": {
    display: "none", // hide the ripple effect
  },
  "& .MuiCheckbox-root": {
    color: "#111827",
  },
};

export default function JobsReporting({
  rows,
  selectedRows,
  setSelectedRows,
  apiRef,
  setFilteredRows,
}) {
  return (
    <>
      <Box
        sx={{ backgroundColor: "#fff" }}
        className="mt-2 mx-10 shadow-md relative rounded-md mb-16"
      >
        <DataGrid
          sx={sxOverwrite}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          getRowHeight={() => "auto"}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          onStateChange={(state) => {
            const filteredRows = gridExpandedSortedRowIdsSelector(
              state,
              apiRef.current.instanceId
            );
            setFilteredRows(filteredRows);
          }}
          rowSelectionModel={selectedRows}
          apiRef={apiRef}
        />
      </Box>
    </>
  );
}
