import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Buffer } from "buffer";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
  RobotoMedium: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
  },
  Courier: {
    normal: "Courier",
    bold: "Courier-Bold",
    italics: "Courier-Oblique",
    bolditalics: "Courier-BoldOblique",
  },
  Helvetica: {
    normal: "Helvetica",
    bold: "Helvetica-Bold",
    italics: "Helvetica-Oblique",
    bolditalics: "Helvetica-BoldOblique",
  },
  Times: {
    normal: "Times-Roman",
    bold: "Times-Bold",
    italics: "Times-Italic",
    bolditalics: "Times-BoldItalic",
  },
  Symbol: {
    normal: "Symbol",
  },
  ZapfDingbats: {
    normal: "ZapfDingbats",
  },
};

// const printer = new PdfPrinter(fonts);

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  return `(${phoneNumber.substring(2, 5)}) ${phoneNumber.substring(
    5,
    8
  )}-${phoneNumber.substring(8, 12)}`;
};

const formatDate = (date, locale = "default") => {
  let processedDate = new Date();

  // Check if 'date' exists and has a recognizable format
  if (date) {
    if (date instanceof Date) {
      // Case: JavaScript Date object
      processedDate = date;
    } else if (
      typeof date === "object" &&
      ("seconds" in date || "_seconds" in date)
    ) {
      // Case: Firestore Timestamp (checking for both 'seconds' and '_seconds' to cover different cases)
      const seconds = "seconds" in date ? date.seconds : date._seconds;
      processedDate = new Date(seconds * 1000);
    } else if (typeof date === "number") {
      // Case: Manual timestamp number
      processedDate = new Date(date);
    } else if (typeof date === "string") {
      // Case: Date string representation
      const parsedDate = Date.parse(date);
      if (!isNaN(parsedDate)) {
        processedDate = new Date(parsedDate);
      }
    } else {
      console.log("date was not caught by any if statment", date);
      console.log("date type", typeof date);
    }
  }
  return processedDate.toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const calcSubTotal = (lineItems) => {
  return formatPrice(
    lineItems.reduce((total, item) => total + item.unitPrice * item.quantity, 0)
  );
};

const findNotNullTaxRateName = (lineItems) => {
  const itemWithTaxRate = lineItems.find((item) => item.taxRate?.name);
  return itemWithTaxRate ? itemWithTaxRate.taxRate.name : null;
};

const findNotNullTaxRateRate = (lineItems) => {
  const itemWithTaxRate = lineItems.find((item) => item.taxRate?.rate);
  return itemWithTaxRate ? itemWithTaxRate.taxRate.rate : null;
};

const calcTotalTax = (lineItems) => {
  const totalTax = lineItems.reduce((total, item) => {
    // Calculate the subtotal for the current item.
    const itemSubTotal = item.unitPrice * item.quantity;
    // Check if the item has a tax rate.
    const taxRate = item.taxRate?.rate || 0; // Default to 0 if no tax rate is present.

    // Calculate tax for the current item if a tax rate exists. If taxRate is 0, the itemTax will be 0.
    const itemTax = (parseFloat(taxRate) / 100) * parseFloat(itemSubTotal);

    total += itemTax;

    return total;
  }, 0);

  return formatPrice(totalTax);
};

const calcTotalForLineItem = (unitPrice, quantity) => {
  return formatPrice(parseFloat(unitPrice) * quantity);
};

const calcTotal = (lineItems) => {
  const totalAmount = lineItems.reduce((total, item) => {
    // Calculate the subtotal for the current item.
    const itemSubTotal = item.unitPrice * item.quantity;
    // Check if the item has a tax rate.
    const taxRate = item.taxRate?.rate || 0; // Default to 0 if no tax rate is present.

    // Calculate tax for the current item if a tax rate exists. If taxRate is 0, the itemTax will be 0.
    const itemTax = (parseFloat(taxRate) / 100) * parseFloat(itemSubTotal);

    // Add both item subtotal and item tax to the total.
    total += itemSubTotal + itemTax;

    // Return the updated total for the next iteration.
    return total;
  }, 0);

  return formatPrice(totalAmount);
};

const formatPrice = (price) => {
  if (!price) return "";
  return parseFloat(price).toFixed(2);
};

async function getDataURL(url) {
  const response = await axios.get(url, { responseType: "arraybuffer" });
  const buffer = Buffer.from(response.data, "binary");
  const base64 = buffer.toString("base64");
  const dataURL = `data:${response.headers["content-type"]};base64,${base64}`;
  return dataURL;
}

function splitAddress(address) {
  const parts = address.split(",").map((part) => part.trim());
  // console.log("parts length", parts.length);

  if (parts.length === 1) {
    // Handle the case where there are no commas in the address
    return [parts[0]];
  }

  if (parts.length === 2) {
    // if there are only two parts then we assume the first is the street and the second is the rest
    return [parts[0], parts[1]];
  }

  // Initialize firstLine with the first part
  let firstLine = parts[0];

  // Assume the rest of the parts constitute the second line initially
  let secondLine = parts.slice(1).join(", ");

  if (parts.length > 1) {
    const potentialUnitOrApt = parts[1];

    // Regular expression to check if the second part looks like an apt, unit, etc.
    // This regex looks for common indicators of an apartment, suite, or unit]
    // we should also just check if there is a number in it? bc not alot of cites have numbers...
    const aptUnitRegex = /^(Apt|Unit|Suite|No|#)?\s*\d+/i;

    // Check if the second part is a unit or apartment/suite number
    if (aptUnitRegex.test(potentialUnitOrApt)) {
      // If true, append it to firstLine and adjust secondLine accordingly
      firstLine += `, ${potentialUnitOrApt}`;
      secondLine = parts.slice(2).join(", ");
    }
  }

  // Return an array where the first element is the first line (street address)
  // and the second element is the rest (city, state, ZIP, optionally country)
  return [firstLine, secondLine];
}

export async function createInvoicePDF(invoice, businessData) {
  try {
    let logoDataURL = null;
    if (invoice.invoiceSettings.logo && businessData?.logo) {
      logoDataURL = await getDataURL(businessData.logo);
    }

    let locale = "en-US";
    if (businessData?.language === "French") {
      locale = "fr-CA";
    }

    const businessAddressSplit = splitAddress(businessData.address || "");
    const customerAddressSplit = splitAddress(invoice.customerAddress || "");

    const taxRateName = findNotNullTaxRateName(invoice.lineItems) || null;
    const taxRateRate = findNotNullTaxRateRate(invoice.lineItems) || null;

    let numberOfSubTotalLines = 2;
    invoice.invoiceSettings.subtotal && numberOfSubTotalLines++;
    invoice.invoiceSettings.tax &&
      taxRateName &&
      taxRateRate &&
      numberOfSubTotalLines++;

    const myTableLayouts = {
      exampleLayout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return i === node.table.headerRows ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? "blue" : "#aaa";
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return i === node.table.widths.length - 1 ? 0 : 8;
        },
      },
      lineItemsLayout: {
        hLineWidth: function (i, node) {
          if (i === 0) {
            return 0;
          }
          if (i === node.table.headerRows) {
            return 1;
          }
          return 0.5;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          if (i === node.table.headerRows) {
            return "#9ca3af";
          }
          return "#e5e7eb";
        },
        paddingLeft: function (i, node) {
          if (i === 0) {
            return 5; // Reduced padding for the leftmost column
          }
          return 10;
        },
        paddingRight: function (i, node) {
          // if (i === node.table.widths.length - 1) {
          //   return 5; // Reduced padding for the rightmost column
          // }
          return 10;
        },
      },
      subTotalLayout: {
        hLineWidth: function (i, node) {
          if (numberOfSubTotalLines === 1) {
            return 0;
          }
          if (numberOfSubTotalLines === 2) {
            if (i === 1) {
              return 1;
            }
          }
          if (numberOfSubTotalLines === 3) {
            if (i === 1 || i === 2) {
              return 1;
            }
          }
          if (numberOfSubTotalLines === 4) {
            if (i === 2 || i === 3) {
              return 1;
            }
          }
          return 0;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          if (numberOfSubTotalLines === 2) {
            if (i === 1) {
              return "#9ca3af";
            }
            return "#e5e7eb";
          }
          if (numberOfSubTotalLines === 3) {
            if (i === 2) {
              return "#9ca3af";
            }
            return "#e5e7eb";
          }
          return "#e5e7eb";
        },
        paddingLeft: function (i, node) {
          if (i === 0) {
            return 5; // Reduced padding for the leftmost column
          }
          return 10;
        },
        paddingRight: function (i, node) {
          // if (i === node.table.widths.length - 1) {
          //   return 5; // Reduced padding for the rightmost column
          // }
          return 10;
        },
      },
    };

    const docDefinition = {
      defaultStyle: {
        font: "Roboto",
      },
      footer: function (currentPage, pageCount) {
        return {
          stack: [
            {
              canvas: [
                {
                  type: "line",
                  x1: 10,
                  y1: 0,
                  x2: 595 - 10,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: "#9ca3af",
                },
              ],
            },
            {
              columns: [
                {
                  text: invoice.invoiceSettings?.footerBusinessName
                    ? `${businessData.companyName}`
                    : "",
                  style: "footerText",
                  margin: [40, 10, 0, 10], // left, top, right, bottom
                },
                {
                  text: invoice.invoiceSettings?.footerBusinessWebsite
                    ? `${businessData.companyWebsite}`
                    : "",
                  style: "footerText",
                  alignment: "center",
                  margin: [0, 10, 0, 10], // left, top, right, bottom
                },
                {
                  text: `${currentPage}/${pageCount}`,
                  style: "footerText",
                  alignment: "right",
                  margin: [0, 10, 40, 10], // left, top, right, bottom
                },
              ],
            },
          ],
          margin: [0, 0, 0, 0], // left, top, right, bottom
        };
      },
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: "Invoice",
                  style: "header",
                  alignment: "left",
                  margin: [0, 0, 0, 10], // left, top, right, bottom
                },
                {
                  columns: [
                    {
                      stack: [
                        invoice.invoiceSettings.invoiceNumber && {
                          text: `Number: ${invoice.invoiceNumber || 12}`,
                          style: "info",
                        },
                        {
                          text: `Service Date: ${formatDate(
                            invoice.serviceDate,
                            locale
                          )}`,
                          style: "info",
                        },
                        {
                          text: `Due: Upon receipt`,
                          style: "info",
                        },
                      ].filter(Boolean), // what does this do?
                      alignment: "left",
                    },
                  ],
                  columnGap: 0,
                },
              ],
              margin: [0, 0, 0, 20], // left, top, right, bottom
              width: "*",
            },

            invoice.invoiceSettings.logo &&
              logoDataURL && {
                image: logoDataURL,
                width: 100,
                alignment: "right", // want this to align top as well..
                margin: [0, 0, 0, 20],
              },
          ].filter(Boolean),
        },

        {
          columns: [
            {
              stack: [
                invoice.invoiceSettings.customerDisplayName && {
                  text: invoice.customerName,
                  style: "medium",
                },
                ...(invoice.invoiceSettings.customerAddress &&
                customerAddressSplit?.[0]
                  ? [
                      { text: customerAddressSplit[0], style: "info" },
                      ...(customerAddressSplit?.[1]
                        ? [{ text: customerAddressSplit[1], style: "info" }]
                        : []),
                    ]
                  : []),

                invoice.invoiceSettings.customerEmail &&
                  invoice.customerEmail?.[0] && {
                    text: `${invoice.customerEmail[0]}`,
                    style: "info",
                  },
                invoice.invoiceSettings.customerPhone &&
                  invoice.customerPhone && {
                    text: `${formatPhoneNumber(invoice.customerPhone)}`,
                    style: "info",
                  },
              ].filter(Boolean),
            },
            {
              stack: [
                invoice.invoiceSettings.businessName && {
                  text: businessData.companyName,
                  style: "medium",
                },
                ...(invoice.invoiceSettings.businessAddress &&
                businessAddressSplit?.[0]
                  ? [
                      { text: businessAddressSplit[0], style: "info" },
                      ...(businessAddressSplit?.[1]
                        ? [{ text: businessAddressSplit[1], style: "info" }]
                        : []),
                    ]
                  : []),
                invoice.invoiceSettings.businessEmail &&
                  businessData.email && {
                    text: `${businessData.email}`,
                    style: "info",
                  },
                invoice.invoiceSettings.businessPhone &&
                  businessData.companyPhone && {
                    text: `${formatPhoneNumber(businessData.companyPhone)}`,
                    style: "info",
                  },
              ].filter(Boolean),
              alignment: "right",
            },
          ],
          margin: [0, 0, 0, 20], // left, top, right, bottom
        },
        {
          margin: [0, 30, 0, 0], // left, top, right, bottom
          table: {
            headerRows: 1,
            widths: [
              "*",
              ...(invoice.invoiceSettings.lineItemsQuantity ? ["auto"] : []),
              ...(invoice.invoiceSettings.lineItemsUnitPrice ? ["auto"] : []),
              ...(invoice.invoiceSettings.lineItemsAmount ? ["auto"] : []),
            ],

            body: [
              [
                { text: "Description", style: "tableHeaderLeft" },
                ...(invoice.invoiceSettings.lineItemsQuantity
                  ? [{ text: "Qty", style: "tableHeader" }]
                  : []),
                ...(invoice.invoiceSettings.lineItemsUnitPrice
                  ? [{ text: "Unit Price", style: "tableHeader" }]
                  : []),
                ...(invoice.invoiceSettings.lineItemsAmount
                  ? [{ text: "Amount", style: "tableHeaderRight" }]
                  : []),
              ],
              ...invoice.lineItems.map((item) => [
                {
                  stack: [
                    {
                      text: item.name,
                      style:
                        item?.description &&
                        invoice?.invoiceSettings?.lineItemsDescription
                          ? "tableNameWithDescription"
                          : "tableLineItem",
                    },
                    ...(item.description &&
                    invoice.invoiceSettings.lineItemsDescription
                      ? [
                          {
                            text: item.description,
                            style: "tableLineItemDescription",
                          },
                        ]
                      : []),
                  ],
                },

                ...(invoice.invoiceSettings.lineItemsQuantity
                  ? [
                      {
                        text: parseFloat(item.quantity).toFixed(1),
                        style: "tableLineItem",
                        alignment: "right",
                      },
                    ]
                  : []),
                ...(invoice.invoiceSettings.lineItemsUnitPrice
                  ? [
                      {
                        text: item.unitPrice
                          ? `$${formatPrice(item.unitPrice)}`
                          : "",
                        style: "tableLineItem",
                      },
                    ]
                  : []),
                ...(invoice.invoiceSettings.lineItemsAmount
                  ? [
                      {
                        text:
                          item.unitPrice && item.quantity
                            ? `$${calcTotalForLineItem(
                                item.unitPrice,
                                item.quantity
                              )}`
                            : "",
                        style: "tableLineItem",
                        alignment: "right",
                      },
                    ]
                  : []),
              ]),
            ],
          },
          layout: "lineItemsLayout",
        },
        {
          columns: [
            {},
            {
              style: "subTotalBox",
              table: {
                widths: ["*", "auto"],
                body: [
                  ...(invoice.invoiceSettings.subtotal
                    ? [
                        [
                          {
                            text: "Subtotal",
                            alignment: "left",
                            margin:
                              numberOfSubTotalLines == 4
                                ? [0, 10, 0, 0]
                                : [0, 10, 0, 10], // left, top, right, bottom
                          },
                          {
                            text: `$${calcSubTotal(invoice.lineItems)}`,
                            alignment: "right",
                            margin:
                              invoice.lineItems.length == 4
                                ? [0, 10, 0, 0]
                                : [0, 10, 0, 10], // left, top, right, bottom
                          },
                        ],
                      ]
                    : []),
                  ...(invoice.invoiceSettings.tax && taxRateRate && taxRateName
                    ? [
                        [
                          {
                            text: `Tax (${taxRateName} ${taxRateRate}%)`,
                            alignment: "left",
                            margin: [0, 10, 0, 10], // left, top, right, bottom
                          },
                          {
                            text: `$${calcTotalTax(invoice.lineItems)}`,
                            alignment: "right",
                            margin: [0, 10, 0, 10], // left, top, right, bottom
                          },
                        ],
                      ]
                    : []),
                  [
                    {
                      text: "Total",
                      //   style: "bold",
                      alignment: "left",
                      margin: [0, 10, 0, 10], // left, top, right, bottom
                    },
                    {
                      text: `$${calcTotal(invoice.lineItems)}`,
                      //   style: "bold",
                      alignment: "right",
                      margin: [0, 10, 0, 10], // left, top, right, bottom
                    },
                  ],
                  [
                    {
                      text: "Amount Due",
                      style: "bold",
                      alignment: "left",
                      margin: [0, 10, 0, 10], // left, top, right, bottom
                    },
                    {
                      text: `$${calcTotal(invoice.lineItems)}`,
                      style: "bold",
                      alignment: "right",
                      margin: [0, 10, 0, 10], // left, top, right, bottom
                    },
                  ],
                ],
              },
              layout: "subTotalLayout",
              alignment: "right",
            },
          ],
        },
        {
          text: invoice.invoiceSettings.note || "",
          style: "info",
          margin: [0, 20, 0, 10],
          alignment: "left",
        },
      ],
      styles: {
        footerText: {
          fontSize: 8,
          color: "#6b7280",
        },
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10],
          color: "#374151",
        },
        bold: {
          bold: true,
          fontSize: 14,
        },
        semibold: {
          bold: true,
          fontSize: 12,
        },
        medium: {
          font: "RobotoMedium",
          fontSize: 12, // Adjust the font size as needed
          margin: [0, 2, 0, 2], // left, top , right , bottom
        },
        info: {
          fontSize: 11,
          margin: [0, 2, 0, 2], // left, top , right , bottom
          color: "#374151",
        },
        infoAddress: {
          fontSize: 12,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          font: "RobotoMedium",
          fontSize: 10,
          noWrap: true,

          // fillColor: "#f3f4f6",
        },
        tableHeaderLeft: {
          font: "RobotoMedium",
          fontSize: 10,
          alignment: "left",
          noWrap: true,
          // fillColor: "#f3f4f6",
        },
        tableHeaderRight: {
          font: "RobotoMedium",
          fontSize: 10,
          alignment: "right",
          noWrap: true,
        },

        tableNameWithDescription: {
          fontSize: 11,
          margin: [0, 10, 0, 0],
        },

        tableLineItem: {
          margin: [0, 10, 0, 10],
          fontSize: 11,
        },
        tableLineItemDescription: {
          margin: [5, 2, 0, 10], // left, top, right, bottom
          fontSize: 9,
          color: "#6b7280",
        },
        subTotalBox: {
          // fillColor: "#f3f4f6",
          margin: [0, 20, 0, 0],
          width: "15%",
          alignment: "right",
        },
      },
    };

    return pdfMake.createPdf(docDefinition, myTableLayouts, fonts);
    // .download("Invoice #" + invoice.invoiceNumber)

    // const pdfDoc = printer.createPdfKitDocument(docDefinition, {
    //   tableLayouts: myTableLayouts,
    // });

    // // // for testing document creation quickly..
    // // pdfDoc.pipe(fs.createWriteStream("TESTINVOICE.pdf"));
    // // pdfDoc.end();
    // // console.log("Invoice PDF generated");

    // const pdfBuffer = await new Promise((resolve, reject) => {
    //   const chunks = [];
    //   pdfDoc.on("data", (chunk) => chunks.push(chunk));
    //   pdfDoc.on("end", () => resolve(Buffer.concat(chunks)));
    //   pdfDoc.on("error", reject);
    //   pdfDoc.end();
    // });

    // const pdfBase64 = pdfBuffer.toString("base64");

    // const attachment = new Attachment();
    // attachment.setContent(pdfBase64);
    // attachment.setFilename(`invoice#${invoice.invoiceNumber}.pdf`);
    // attachment.setType("application/pdf");
    // attachment.setDisposition("attachment");

    // return { attachment, pdfBytes: pdfBuffer };
  } catch (error) {
    console.log("Error creating invoice PDF", error);
    return { error: error.message };
  }
}
