import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Checkbox } from "components/ui/checkbox";
import InputMUI from "components/reusable/InputMUI";
import { useState } from "react";
import { TextareaAutosize } from "@mui/material";

export const InvoicePreviewSettings = ({
  invoiceSettings,
  setInvoiceSettings,
  isCustomizations = false,
}) => {
  const [note, setNote] = useState(invoiceSettings?.note || "");

  const handleCheckboxChange = (field) => {
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      [field]: !prevSettings[field],
    }));
  };

  const handleSaveNote = () => {
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      note,
    }));
  };

  const handleRevertNote = () => {
    setNote(invoiceSettings.note || "");
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
    if (isCustomizations) {
      setInvoiceSettings((prevSettings) => ({
        ...prevSettings,
        note: e.target.value,
      }));
    }
  };

  return (
    <div className="w-full sm:max-w-sm">
      <div className="pb-4">
        <h2 className="text-xl font-semibold">Invoice Settings</h2>
      </div>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <p className="font-medium">Logo</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center">
              <Checkbox
                checked={invoiceSettings?.logo}
                onCheckedChange={() => handleCheckboxChange("logo")}
              />
              <span className="ml-2">Display Logo</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <p className="font-medium">Invoice Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.invoiceNumber}
                onCheckedChange={() => handleCheckboxChange("invoiceNumber")}
              />
              <span className="ml-2">Invoice Number</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <p className="font-medium">Customer Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.customerDisplayName}
                onCheckedChange={() =>
                  handleCheckboxChange("customerDisplayName")
                }
              />
              <span className="ml-2">Display Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.customerAddress}
                onCheckedChange={() => handleCheckboxChange("customerAddress")}
              />
              <span className="ml-2">Address</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.customerEmail}
                onCheckedChange={() => handleCheckboxChange("customerEmail")}
              />
              <span className="ml-2">Email</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.customerPhone}
                onCheckedChange={() => handleCheckboxChange("customerPhone")}
              />
              <span className="ml-2">Phone number</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <p className="font-medium">Business Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.businessName}
                onCheckedChange={() => handleCheckboxChange("businessName")}
              />
              <span className="ml-2">Business Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.businessEmail}
                onCheckedChange={() => handleCheckboxChange("businessEmail")}
              />
              <span className="ml-2">Email</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.businessPhone}
                onCheckedChange={() => handleCheckboxChange("businessPhone")}
              />
              <span className="ml-2">Phone</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.businessAddress}
                onCheckedChange={() => handleCheckboxChange("businessAddress")}
              />
              <span className="ml-2">Address</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            <p className="font-medium">Line Items</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.lineItemsDescription}
                onCheckedChange={() =>
                  handleCheckboxChange("lineItemsDescription")
                }
              />
              <span className="ml-2">Description</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.lineItemsQuantity}
                onCheckedChange={() =>
                  handleCheckboxChange("lineItemsQuantity")
                }
              />
              <span className="ml-2">Quantity</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.lineItemsUnitPrice}
                onCheckedChange={() =>
                  handleCheckboxChange("lineItemsUnitPrice")
                }
              />
              <span className="ml-2">Unit Price</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.lineItemsAmount}
                onCheckedChange={() => handleCheckboxChange("lineItemsAmount")}
              />
              <span className="ml-2">Amount</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <p className="font-medium">Total</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.subtotal}
                onCheckedChange={() => handleCheckboxChange("subtotal")}
              />
              <span className="ml-2">Subtotal</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.tax}
                onCheckedChange={() => handleCheckboxChange("tax")}
              />
              <span className="ml-2">Tax</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
          >
            <p className="font-medium">Footer</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.footerBusinessName}
                onCheckedChange={() =>
                  handleCheckboxChange("footerBusinessName")
                }
              />
              <span className="ml-2">Business Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={invoiceSettings?.footerBusinessWebsite}
                onCheckedChange={() =>
                  handleCheckboxChange("footerBusinessWebsite")
                }
              />
              <span className="ml-2">Website</span>
            </label>
          </AccordionDetails>
        </Accordion>

        {isCustomizations && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel9-content"
              id="panel9-header"
            >
              <p className="font-medium">Payment</p>
            </AccordionSummary>
            <AccordionDetails>
              <label className="flex items-center mb-2">
                <Checkbox
                  checked={invoiceSettings?.savePaymentMethod}
                  onCheckedChange={() =>
                    handleCheckboxChange("savePaymentMethod")
                  }
                />
                <span className="ml-2">Save Card On File</span>
              </label>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
          >
            <p className="font-medium">Note</p>
          </AccordionSummary>
          <AccordionDetails>
            <textarea
              className="resize-none w-full border rounded-sm p-2 text-sm outline-none h-24"
              placeholder="Enter note that will appear at the bottom of the invoice..."
              value={note}
              onChange={onNoteChange}
            />
            {!isCustomizations && (
              <div className="flex w-full justify-end gap-2">
                {/* <button
                // disabled={invoiceSettings.note !== note}
                type="button"
                className="rounded bg-white px-3 py-1.5 text-sm font-medium text-black  hover:bg-gray-50 disabled:bg-gray-200"
                onClick={handleRevertNote}
              >
                Revert
              </button> */}
                <button
                  // disabled
                  disabled={invoiceSettings.note === note}
                  type="button"
                  // className="rounded border border-gray-900 bg-gray-900 px-3 py-1.5 text-sm font-semibold text-white  hover:bg-gray-700 disabled:bg-gray-400 disabled:border-gray-400"
                  className="rounded  bg-gray-200 px-3 py-1.5 text-sm font-semibold text-black  hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-400"
                  onClick={handleSaveNote}
                >
                  Save
                </button>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        {/* <div className="my-4">
          <TextareaAutosize className="resize-none w-full shadow rounded-sm p-4 focus-visible:ring-0 border-none" />
        </div> */}
      </div>
    </div>
  );
};

const EstimateOptions = ({ invoiceSettings, setinvoiceSettings }) => {
  const handleCheckboxChange = (field) => {
    setinvoiceSettings((prevSettings) => ({
      ...prevSettings,
      [field]: !prevSettings[field],
    }));
  };

  return (
    <div className="px-4">
      <h2 className="text-xl font-bold mb-4">Logo</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.logo}
          onCheckedChange={() => handleCheckboxChange("logo")}
        />
        <span className="ml-2">Display Logo</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Estimate Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.estimateNumber}
          onCheckedChange={() => handleCheckboxChange("estimateNumber")}
        />
        <span className="ml-2">Estimate Number</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.estimateDate}
          onCheckedChange={() => handleCheckboxChange("estimateDate")}
        />
        <span className="ml-2">Estimate Date</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Customer Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerDisplayName}
          onCheckedChange={() => handleCheckboxChange("customerDisplayName")}
        />
        <span className="ml-2">Display Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerAddress}
          onCheckedChange={() => handleCheckboxChange("customerAddress")}
        />
        <span className="ml-2">Address</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerEmail}
          onCheckedChange={() => handleCheckboxChange("customerEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.customerPhone}
          onCheckedChange={() => handleCheckboxChange("customerPhone")}
        />
        <span className="ml-2">Phone number</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Business Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessName}
          onCheckedChange={() => handleCheckboxChange("businessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessEmail}
          onCheckedChange={() => handleCheckboxChange("businessEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessPhone}
          onCheckedChange={() => handleCheckboxChange("businessPhone")}
        />
        <span className="ml-2">Phone</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.businessAddress}
          onCheckedChange={() => handleCheckboxChange("businessAddress")}
        />
        <span className="ml-2">Address</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Line Items</h2>

      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsDescription}
          onCheckedChange={() => handleCheckboxChange("lineItemsDescription")}
        />
        <span className="ml-2">Description</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsQuantity}
          onCheckedChange={() => handleCheckboxChange("lineItemsQuantity")}
        />
        <span className="ml-2">Quantity</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsUnitPrice}
          onCheckedChange={() => handleCheckboxChange("lineItemsUnitPrice")}
        />
        <span className="ml-2">Unit Price</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.lineItemsAmount}
          onCheckedChange={() => handleCheckboxChange("lineItemsAmount")}
        />
        <span className="ml-2">Amount</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Total</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.subtotal}
          onCheckedChange={() => handleCheckboxChange("subtotal")}
        />
        <span className="ml-2">Subtotal</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.tax}
          onCheckedChange={() => handleCheckboxChange("tax")}
        />
        <span className="ml-2">Tax</span>
      </label>
      <h2 className="text-xl font-bold mt-6 mb-4">Footer</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.footerBusinessName}
          onCheckedChange={() => handleCheckboxChange("footerBusinessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={invoiceSettings?.footerBusinessWebsite}
          onCheckedChange={() => handleCheckboxChange("footerBusinessWebsite")}
        />
        <span className="ml-2">Website</span>
      </label>
    </div>
  );
};

export default EstimateOptions;
