import { FaFile, FaFilePdf } from "react-icons/fa";

export default function DocumentDisplayInChat({ uri }) {
  const urlParts = uri.split("?");
  const baseUrl = urlParts[0];
  const queryParams = urlParts[1] ? new URLSearchParams(urlParts[1]) : null;

  const fileExtension = baseUrl.split(".").pop()?.toLowerCase();
  const extParam = queryParams?.get("ext")?.toLowerCase();

  // remove uri params, preceding firebase storage url structure and tag "message-images",
  const nameWithPrecedingDate = uri.split("?")[0].split("/").pop().slice(15);

  // remove preceding stringified date and file extension
  const name = nameWithPrecedingDate.slice(
    nameWithPrecedingDate.indexOf("-") + 1,
    nameWithPrecedingDate.lastIndexOf(".")
  );

  const renderFileIcon = () => {
    if (fileExtension === "pdf" || extParam === "pdf") {
      return <FaFilePdf style={{ fontSize: "65px", color: "#E74C3C" }} />;
    } else {
      return <FaFile style={{ fontSize: "65px", color: "#3498DB" }} />;
    }
  };

  return (
    // make this whole thing a link to the uri
    <a href={uri} target="_blank" rel="noreferrer">
      <div style={styles.container}>
        <div style={styles.frame}>
          {renderFileIcon()}
          <div>
            <div style={styles.text}>
              {name.replace("%20", "").replace(" ", "").length > 15
                ? name.replace("%20", "").replace(" ", "").substring(0, 15) +
                  "..."
                : name.replace("%20", "").replace(" ", "")}
            </div>
            <div style={styles.textType}>
              {(fileExtension || extParam)?.toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

const styles = {
  container: {
    marginTop: "5px",
    borderRadius: "15px",
    padding: "5px",
  },
  text: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    lineHeight: "20px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  textType: {
    color: "black",
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  frame: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "10px",
    padding: "5px",
    marginTop: "-4px",
  },
};
