import { db } from "utils/firebase";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getCountFromServer,
  increment,
} from "firebase/firestore";
import { node } from "constants/constants";
import { sendInvoiceOnCall } from "../../../utils/firebaseApiCalls";

export const fetchInvoice = async ({ businessId, invoiceId }) => {
  try {
    const invoiceRef = doc(db, "businesses", businessId, "invoices", invoiceId);
    const invoiceSnap = await getDoc(invoiceRef);

    if (!invoiceSnap.exists()) {
      console.error("Invoice not found");
      return null;
    }

    return invoiceSnap.data();
  } catch (error) {
    console.error("Error fetching invoice:", error);
    throw error;
  }
};

export const generateInvoiceNumber = async ({ businessId }) => {
  try {
    const invoicesRef = collection(db, "businesses", businessId, "invoices");

    // Get the count of existing invoices
    const snapshot = await getCountFromServer(invoicesRef);
    const invoicesCount = snapshot.data().count;

    // Set the base invoice number
    let newInvoiceNumber = invoicesCount;

    // Special handling for specific business (Remi)
    if (businessId === "8fHMrcSYmDdVXsA9wkt5dGjnPgr1") {
      newInvoiceNumber = 348 + invoicesCount;
    }

    return newInvoiceNumber;
  } catch (error) {
    console.error("Error generating invoice number:", error);
    throw error;
  }
};

export const createInvoice = async (userData, jobDetails, invoiceDetails) => {
  const invoiceRef = doc(
    collection(db, "businesses", userData.userData?.businessId, "invoices")
  );
  const jobRef = doc(
    db,
    "businesses",
    userData.userData?.businessId,
    "jobs",
    jobDetails.jobId
  );

  await setDoc(invoiceRef, {
    invoiceId: invoiceRef.id,
    ...invoiceDetails,
    ignoreOnCreated: true,
  });

  updateDoc(jobRef, {
    invoiceId: invoiceRef.id,
  });
  return invoiceRef.id;
};

export const updateInvoice = async (userData, jobDetails, invoiceDetails) => {
  if (!jobDetails || !userData || !jobDetails.invoiceId) return;

  const { invoiceId } = jobDetails;

  const invoiceRef = doc(
    db,
    "businesses",
    userData.userData?.businessId,
    "invoices",
    invoiceId
  );

  // Adding id as a precaution in case some dont have
  await updateDoc(invoiceRef, { invoiceId, ...invoiceDetails });

  return invoiceId;
};

export const createAndSendInvoice = async (
  userData,
  jobDetails,
  invoiceDetails,
  shouldSendEmail,
  shouldSendPhone,
  emailToSend,
  phoneToSend,
  message
) => {
  if (
    !userData.bizData.stripeAccountId ||
    userData.bizData?.stripe_charges_enabled === false ||
    userData.bizData?.stripe_payouts_enabled === false ||
    userData.bizData?.stripe_details_submitted === false
  ) {
    alert(
      "You must set up Homebase360 payments before you can send an invoice. Please go to the Homebase360 payments page to connect your stripe account."
    );
    return;
  }
  if (
    !jobDetails ||
    !userData ||
    !userData.bizData ||
    !jobDetails?.jobTotal ||
    !jobDetails?.lineItems
  )
    return;

  try {
    let invoiceId;
    if (jobDetails.invoiceId) {
      invoiceId = await updateInvoice(userData, jobDetails, invoiceDetails);
    } else {
      invoiceId = await createInvoice(userData, jobDetails, invoiceDetails);
    }
    console.log("invoiceOd", invoiceId);
    return sendInvoiceOnCall({
      invoiceId,
      businessData: userData.bizData,
      emailToSend: shouldSendEmail ? emailToSend : null,
      phoneToSend: shouldSendPhone ? phoneToSend : null,
      customMessage: message,
    });
  } catch (error) {
    console.error(error);
    alert("Error creating invoice, please try again later.");
  }
};
