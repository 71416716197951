import { db } from "utils/firebase";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { convertToJsDateNano } from "utils/helpers";
import { massText, sendMessage } from "utils/twilio";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { formatDateTimezone } from "utils/helpers";
import { formatPhoneDisplay } from "utils/formatPhone";
import { format, utcToZonedTime } from "date-fns-tz";

// filter on client side
export const filterCustomersClient = (customers, filterOptions) => {
  const dateCompareProblem = (customerDate, filterDate, greater = true) => {
    if (!customerDate) return true;
    return greater
      ? convertToJsDateNano(customerDate) < new Date(filterDate)
      : convertToJsDateNano(customerDate) > new Date(filterDate); // return true if out of range
  };

  let filteredCustomers = [...customers].filter((el) => {
    for (const key in filterOptions) {
      switch (key) {
        case "createdAtStartDate":
          if (
            dateCompareProblem(
              el.dateAdded,
              filterOptions.createdAtStartDate,
              true
            )
          )
            return false;
          break;
        case "notifications":
          if (el.notifications !== filterOptions.notifications) return false;
          break;
        case "createdAtEndDate":
          if (
            dateCompareProblem(
              el.dateAdded,
              filterOptions.createdAtEndDate,
              false
            )
          )
            return false;
          break;
        case "lastJobStartDate":
          if (
            dateCompareProblem(el.lastJob, filterOptions.lastJobStartDate, true)
          )
            return false;
          break;
        case "lastJobEndDate":
          if (
            dateCompareProblem(el.lastJob, filterOptions.lastJobEndDate, false)
          )
            return false;
          break;
        case "city":
          if (
            !el.cities ||
            !el.cities.includes(filterOptions.city.toLowerCase())
          )
            return false;
          break;
        case "tag":
          if (!el.tags || !el.tags.includes(filterOptions.tag)) return false;
          break;
        default:
          break;
      }
    }
    return true;
  });
  if (filterOptions.orderBy) {
    const [orderByField, direction] = filterOptions.orderBy;
    filteredCustomers.sort((a, b) => {
      // isolate a and b to compare
      [a, b] = [a[orderByField], b[orderByField]];
      if (typeof a === "object" && a.length > 0) a = a[0];
      if (typeof b === "object" && b.length > 0) b = b[0];
      if (typeof a === "string") a = a.toUpperCase();
      if (typeof b === "string") b = b.toUpperCase();

      if (direction === "asc") {
        [a, b] = [b, a];
      }

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    });
  }
  return filteredCustomers;
};

// fetch customers from server using up to two filters, if there are more than two filters fetch all then allow client filters to do their work. Paginate accordingly
export const fetchCustomers = async ({ filterOptions, userData }) => {
  if (!userData?.userData?.businessId) {
    console.error("Invalid userData:", userData);
    return [[], null];
  }

  try {
    let queryRef = collection(
      db,
      "businesses",
      userData.userData.businessId,
      "customers"
    );
    let constraints = [];
    let clientSideFilters = { ...filterOptions };
    let totalCount;

    // Apply up to two server-side filters, only one can be an array-contains filter
    let serverFilters = [];
    for (const [key, value] of Object.entries(filterOptions)) {
      switch (key) {
        case "createdAtStartDate":
          if (
            serverFilters.length >= 2 &&
            !serverFilters.includes("createdAtEndDate")
          )
            break; // if a portion of the range exists we can add this one
          constraints.push(where("dateAdded", ">=", new Date(value)));
          delete clientSideFilters.createdAtStartDate; // we dont need to filter this on the client
          if (!serverFilters.includes("createdAtEndDate")) {
            serverFilters.push("createdAtStartDate"); // both of these only count as one filter
          }
          break;
        case "createdAtEndDate":
          if (
            serverFilters.length >= 2 &&
            !serverFilters.includes("createdAtStartDate")
          )
            break; // if a portion of the range exists we can add this one
          constraints.push(where("dateAdded", "<=", new Date(value)));
          delete clientSideFilters.createdAtEndDate;
          if (!serverFilters.includes("createdAtStartDate")) {
            serverFilters.push("createdAtEndDate"); // both of these only count as one filter
          }
          break;
        case "lastJobStartDate":
          if (
            serverFilters.length >= 2 &&
            !serverFilters.includes("lastJobEndDate")
          )
            break; // if a portion of the range exists we can add this one
          constraints.push(where("lastJob", ">=", new Date(value)));
          delete clientSideFilters.lastJobStartDate;
          if (!serverFilters.includes("lastJobEndDate")) {
            serverFilters.push("lastJobStartDate"); // both of these only count as one filter
          }
          break;
        case "lastJobEndDate":
          if (
            serverFilters.length >= 2 &&
            !serverFilters.includes("lastJobStartDate")
          )
            break; // if a portion of the range exists we can add this one
          constraints.push(where("lastJob", "<=", new Date(value)));
          delete clientSideFilters.lastJobEndDate;
          if (!serverFilters.includes("lastJobStartDate")) {
            serverFilters.push("lastJobEndDate"); // both of these only count as one filter
          }
          break;
        case "city":
          if (serverFilters.includes("tag") || serverFilters.length >= 2) break; // can only have one array-contains filter
          constraints.push(
            where("cities", "array-contains", filterOptions.city.toLowerCase())
          );
          serverFilters.push("city");
          delete clientSideFilters.city;
          break;
        case "tag":
          if (serverFilters.includes("city") || serverFilters.length >= 2)
            break; // can only have one array-contains filter
          constraints.push(where("tags", "array-contains", filterOptions.tag));
          serverFilters.push("tag");
          delete clientSideFilters.tag;
          break;
        default:
          break;
      }
    }
    // Prioritize other filters to reduce reads, orderBy & filter by notifications client side if necessary.
    if (serverFilters.length < 2 && filterOptions.orderBy) {
      constraints.push(
        orderBy(filterOptions.orderBy[0], filterOptions.orderBy[1])
      );
      serverFilters.push("orderBy");
      delete clientSideFilters.orderBy;
    }
    if (serverFilters.length < 2 && filterOptions.notifications) {
      constraints.push(where("notifications", "==", true));
      serverFilters.push("notifications");
      delete clientSideFilters.notifications;
    }
    if (filterOptions.lastDoc)
      constraints.push(startAfter(filterOptions.lastDoc));
    // console.log(
    //   "about to query with constraints: ",
    //   constraints,
    //   filterOptions
    // );
    // Fetch all if there are client side filters to apply else fetch amount
    const filtersSet = new Set([
      "tag",
      "city",
      "createdAtStartDate",
      "createdAtEndDate",
      "lastJobStartDate",
      "lastJobEndDate",
      "notifications",
      "orderBy",
    ]);
    const fetchAll = !Object.keys(clientSideFilters).every(
      (filterName) => !filtersSet.has(filterName)
    );
    queryRef = query(queryRef, ...constraints);
    // get count before adding amount filter, if there's a lastDoc we have already gotten the count
    if (filterOptions.amount && !fetchAll && !filterOptions.lastDoc)
      totalCount = (await getCountFromServer(queryRef)).data().count;

    if (filterOptions.amount && !fetchAll) {
      // console.log('querying by amount');
      queryRef = query(queryRef, limit(filterOptions.amount));
    }
    const querySnapshot = await getDocs(queryRef);

    let customers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    // console.log("returning customers from helper: ", customers);

    return {
      customers,
      lastDoc,
      totalCount,
      clientSideFilters,
      localFetchType: fetchAll ? "all" : "paginated",
    };
  } catch (error) {
    console.error("Error fetching customers:", error);
    return {
      customers: [],
      lastDoc: null,
      totalCount: 0,
      clientSideFilters: {},
    };
  }
};

// used in some cases of handleReviewRequest, This function will just take the onReviewRequestMessage from the bizData and send it to the customer and then mark the job as reviewMessageSent
// const handleDisabledReviewRequest = async ({ userData, customer }) => {
//   const message = userData?.bizData?.onReviewRequestMessage;
//   const to = customer?.phone?.mobile;
//   const businessId = userData.bizData?.id;
//   const companyName = userData.bizData?.companyName;
//   const customerName = customer?.displayName;

//   if (to && businessId && companyName && message) {
//     // Optimistically update the state
//     // const originalJobDetails = { ...jobDetails };
//     // setJobDetails({
//     //   ...jobDetails,
//     //   reviewMessageSent: new Date(),
//     // });
//     try {
//       const response = await sendMessage(
//         message,
//         to,
//         null,
//         businessId,
//         companyName,
//         customerName
//       );
//       if (!response) {
//         throw new Error("Failed to send message");
//       }

//       // await updateDoc(jobRef, {
//       //   reviewMessageSent: serverTimestamp(),
//       // });
//     } catch (error) {
//       console.log(error);
//       // Revert to the original state in case of an error
//       // setJobDetails(originalJobDetails);
//       alert(
//         "Failed to send message, please check to ensure the mobile number is valid and try again. If the problem persists, please contact support."
//       );
//     }
//   }
// };

// send review request to customer - this is for an individual customer, phasing this out
// export const handleReviewRequest = async ({ userData, customer }) => {
//   // extract variables for later
//   const to = customer?.phone?.mobile;
//   const businessId = userData.bizData?.id;
//   const companyName = userData.bizData?.companyName;
//   const customerName = customer?.displayName;
//   let reviewId = customer?.reviewId;
//   let success = false;

//   // conditional checks
//   if (
//     userData.bizData?.reviewFilterDisabled &&
//     userData.bizData?.onReviewRequestMessage
//   ) {
//     await handleDisabledReviewRequest();
//     return;
//   } else if (
//     !userData.bizData.googleReviewLink &&
//     !userData.bizData.yelpReviewLink &&
//     !userData.bizData.facebookReviewLink
//   ) {
//     throw new Error(
//       "Please fill in your review links in your company profile, so you can receive reviews for your business!"
//     );
//     // alert(
//     //   "Please fill in your review links in your company profile, so you can receive reviews for your business!"
//     // );
//     // return;
//   } else if (!customer?.notifications) {
//     throw new Error("You have notifications turned off for this customer.");
//     // alert("You have notifications turned off for this customer.");
//     // return;
//   } else if (!to || !businessId || !companyName || !customerName) {
//     throw new Error(
//       `Missing ${
//         !to
//           ? "customer mobile number required"
//           : !businessId
//           ? "you have no associated business"
//           : !companyName
//           ? "you have no company name"
//           : "customer name required"
//       } data for sending text`
//     );
//   }

//   // create the review doc in buisnesses/{businessId}/reviews/{reviewId}
//   if (!reviewId) {
//     const reviewRef = doc(
//       collection(db, "businesses", userData.userData?.businessId, "reviews")
//     );
//     reviewId = reviewRef.id;
//     const reviewData = {
//       reviewId,
//       jobId: null,
//       customerId: customer?.customerId,
//       businessId: userData.userData.businessId,
//       businessName: userData.bizData.companyName,
//       businessAddress: userData.bizData.address,
//       businessPhone: userData.bizData.companyPhone,
//       email: userData.bizData.email,
//       customerName: customer?.displayName,
//       customerEmail: customer?.email,
//       customerPhone: customer?.phone?.mobile,
//       reviewMessageSent: serverTimestamp(),
//       googleReviewLink: userData.bizData.googleReviewLink,
//       yelpReviewLink: userData.bizData.yelpReviewLink,
//       facebookReviewLink: userData.bizData.facebookReviewLink,
//     };
//     await setDoc(reviewRef, reviewData);

//     // send review request to customer via text
//     let preLinkMessage =
//       userData?.bizData?.onReviewRequestMessage ||
//       `Please leave us a review. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
//     const message =
//       preLinkMessage +
//       `\n\nhttps://app.homebase360.io/review?id=${userData.userData?.businessId}&reviewId=${reviewRef.id}`;

//     success = await sendMessage(
//       message,
//       to,
//       userData.bizData?.twilioNumber,
//       businessId,
//       companyName,
//       customerName
//     );
//   } else if (customer.reviewId) {
//     // this is where we resend the review request
//     let preLinkMessage =
//       userData?.bizData?.onReviewRequestMessage ||
//       `Please leave us a review. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
//     const message =
//       preLinkMessage +
//       `\n\nhttps://app.homebase360.io/review?id=${userData.userData?.businessId}&reviewId=${customer.reviewId}`;

//     success = await sendMessage(
//       message,
//       to,
//       userData.bizData?.twilioNumber,
//       businessId,
//       companyName,
//       customerName
//     );
//   }
//   return success;
// };

// send review request to customers
export const handleReviewRequests = async ({ userData, customers }) => {
  // global conditional checks
  if (
    !userData.bizData.googleReviewLink &&
    !userData.bizData.yelpReviewLink &&
    !userData.bizData.facebookReviewLink
  ) {
    throw new Error(
      "Please fill in your review links in your company profile, so you can receive reviews for your business!"
    );
  } else if (!userData.bizData?.id) {
    throw new Error("You have no associated business");
  } else if (!userData.bizData?.companyName) {
    throw new Error("You have no company name");
  }

  const batch = writeBatch(db);
  const customersToSendTo = [];

  for (const customer of customers) {
    const to = customer?.phone?.mobile;
    const customerName = customer?.displayName;
    let reviewId = customer?.reviewId;

    if (!customer?.notifications || !to || !customerName) continue;

    // if review id add customer to send list otherwise create the review first then add to send list
    if (reviewId) customersToSendTo.push(customer);
    else {
      const reviewRef = doc(
        collection(db, "businesses", userData.userData?.businessId, "reviews")
      );
      reviewId = reviewRef.id;
      const reviewData = {
        reviewId,
        jobId: null,
        customerId: customer?.customerId,
        businessId: userData.userData.businessId,
        businessName: userData.bizData.companyName,
        businessAddress: userData.bizData.address,
        businessPhone: userData.bizData.companyPhone,
        email: userData.bizData.email,
        customerName: customer?.displayName,
        customerEmail: customer?.email,
        customerPhone: customer?.phone?.mobile,
        reviewMessageSent: serverTimestamp(),
        googleReviewLink: userData.bizData.googleReviewLink,
        yelpReviewLink: userData.bizData.yelpReviewLink,
        facebookReviewLink: userData.bizData.facebookReviewLink,
      };
      batch.set(reviewRef, reviewData);
      customersToSendTo.push({ ...customer, reviewId: reviewRef.id }); // add new reviewId to customer, this is necessary for the massText function to link to the proper review
    }
  }
  await batch.commit();

  let preLinkMessage =
    userData?.bizData?.onReviewRequestMessage ||
    `Please leave us a review. We appreciate your business. \n\n- ${userData.bizData?.companyName}`;
  let message =
    preLinkMessage +
    `\n\nhttps://app.homebase360.io/review?id=${userData.userData?.businessId}&reviewId={{reviewId}}`;

  // use custom message, in some cases
  if (
    userData.bizData?.reviewFilterDisabled &&
    userData.bizData?.onReviewRequestMessage
  )
    message = userData.bizData.onReviewRequestMessage;

  const success = await massText(
    userData.bizData.id,
    message,
    customersToSendTo,
    userData.bizData.companyName,
    userData.bizData?.telnyxNumber || ""
  );
  return {
    noErrors: success,
    sent: customersToSendTo.length,
    notSent: customers.length - customersToSendTo.length,
  };
};

// formats a customer in a human readable format that can be used for display in table, CSV or other
export const formatCustomerForDisplay = (customer, userData) => ({
  ...customer,
  address: customer?.address?.[0] || "",
  dateAdded: formatDateTimezone(
    customer.dateAdded,
    userData?.bizData?.timeZone,
    "MMM dd, yyyy"
  ),
  lastUpdated: formatDateTimezone(
    customer.lastUpdated,
    userData?.bizData?.timeZone,
    "MMM dd, yyyy"
  ),
  lastJob: formatDateTimezone(
    customer.lastJob,
    userData?.bizData?.timeZone,
    "MMM dd, yyyy"
  ),

  phone: formatPhoneDisplay(
    customer.phone?.mobile ||
      customer.phone?.work ||
      customer.phone?.additional ||
      customer.phone?.home ||
      ""
  ),
  notes:
    customer.notes?.length > 300
      ? customer.notes.slice(0, 300) + "..."
      : customer.notes || "",
  email: customer.email?.[0] || "",
  mobilePhone: formatPhoneDisplay(customer.phone?.mobile) || "",
  workPhone: formatPhoneDisplay(customer.phone?.work) || "",
  additionalPhone: formatPhoneDisplay(customer.phone?.additional) || "",
  homePhone: formatPhoneDisplay(customer.phone?.home) || "",
  notifications: customer.notifications ? "Enabled" : "Disabled",
  reviewMessageSent: customer.reviewMessageSent
    ? formatDateTimezone(
        customer.reviewMessageSent,
        userData?.bizData?.timeZone,
        "MMM dd, yyyy"
      )
    : "Never",
});

export const handleExportToCsv = async ({
  allCustomers,
  selectedRows,
  columnHeaders,
  userData,
}) => {
  const selectedRowsSet = new Set(selectedRows);
  const customers = allCustomers.filter((customer) =>
    selectedRowsSet.has(customer.id)
  );
  if (!userData?.userData?.isAdmin) {
    console.error("User is not an admin");
    alert("You do not have permission to export customers to CSV");
    return;
  }
  if (customers?.length === 0) {
    console.error("No customers to export");
    alert("No customers to export");
    return;
  }

  try {
    // Organize customer data for the CSV
    // const customersForCsv = customers.map((customer) => ({
    //   name: customer.displayName || "",
    //   address: customer.address?.[0] || "",
    //   emails: customer.email?.length > 0 ? customer.email.join(", ") : "",
    //   mobilePhone: customer.phone?.mobile?.slice(2) || "",
    //   homePhone: customer.phone?.home || "",
    //   workPhone: customer.phone?.work || "",
    //   additionalPhone: customer.phone?.additional || "",
    //   customerNotes: customer.notes || "",
    // }));
    // console.log(
    //   "columnHeaders",
    //   columnHeaders.map((column) => column.field)
    // );
    const customersForCsv = [
      columnHeaders.map((column) => column.headerName),
    ].concat(
      customers.map((customer) => {
        const tableFormattedCustomer = formatCustomerForDisplay(
          customer,
          userData
        );
        return columnHeaders.map(
          (column) => tableFormattedCustomer[column.field] || ""
        );
      })
    );
    // console.log("customersForCsv", customersForCsv);
    // Generate CSV
    const csv = Papa.unparse(customersForCsv);

    // trim company name and replace any spaces with dashes
    const companyName = userData?.bizData?.companyName
      .trim()
      .replace(/\s/g, "-");

    // Use file-saver to save the generated CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `Customers-${companyName}-${new Date().toISOString()}.csv`);
  } catch (error) {
    console.error("Failed to export customers to CSV", error);
  }
};

export const allColumnHeaders = [
  {
    field: "firstName",
    headerName: "First Name",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    // valueGetter: (value) =>
    //   formatPhoneDisplay(
    //     value?.row?.phone?.mobile ||
    //       value?.row?.phone?.work ||
    //       value?.row?.phone?.additional ||
    //       value?.row?.phone?.home
    //   ),
    width: 150,
    disableColumnMenu: true,
    sortable: false, // a nested firetore value makes sorting in firestore more difficult
  },
  {
    field: "email",
    headerName: "Email",
    width: 240,
    disableColumnMenu: true,
    // valueGetter: (value) => value?.row?.email[0] || "",
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "lastUpdated",
    headerName: "Last Updated",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "mobilePhone",
    headerName: "Mobile Phone",
    width: 150,
    // valueGetter: (value) => formatPhoneDisplay(value?.row?.phone?.mobile),
    disableColumnMenu: true,
    sortable: false, // a nested firetore value makes sorting in firestore more difficult
  },
  {
    field: "workPhone",
    headerName: "Work Phone",
    width: 150,
    // valueGetter: (value) => formatPhoneDisplay(value?.row?.phone?.work),
    disableColumnMenu: true,
    sortable: false, // a nested firetore value makes sorting in firestore more difficult
  },
  {
    field: "additionalPhone",
    headerName: "Additional Phone",
    width: 150,
    // valueGetter: (value) => formatPhoneDisplay(value?.row?.phone?.additional),
    disableColumnMenu: true,
    sortable: false, // a nested firetore value makes sorting in firestore more difficult
  },
  {
    field: "homePhone",
    headerName: "Home Phone",
    width: 150,
    // valueGetter: (value) => formatPhoneDisplay(value?.row?.phone?.home),
    disableColumnMenu: true,
    sortable: false, // a nested firetore value makes sorting in firestore more difficult
  },
  {
    field: "notifications",
    headerName: "Notifications",
    width: 150,
    // valueGetter: (value) =>
    //   value?.row?.notifications ? "Enabled" : "Disabled",
    disableColumnMenu: true,
  },
  {
    field: "lastJob",
    headerName: "Last Job",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "reviewMessageSent",
    headerName: "Review Requested",
    width: 150,
    // valueGetter: (value) =>
    //   value?.row?.reviewMessageSent ? value?.row?.reviewMessageSent : "Never",
    disableColumnMenu: true,
    sortable: false, // this being a new firestore field means the field doesnt exist on all customers, you cant order by it if the field doens't exist
  },
];
