import { BsExclamationCircle } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";

const disclosureText = (
  <p>
    Excessive or unprofessional marketing messages, especially unsolicited ones,
    can cause your number to be flagged as spam by message carriers, which will
    result in undelivered text messages.
    <br />
    <br />
    Your clients can also opt out of receiving messages at any time; if they do,
    they will not receive marketing or individual messages from your business.
  </p>
);

export default function DLCDisclosure() {
  return (
    <Tooltip title={disclosureText}>
      <div>
        <BsExclamationCircle className="h-5 mt-1 w-5 text-red-500" />
      </div>
    </Tooltip>
  );
}
