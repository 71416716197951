import useAutosizeTextArea from "components/other/useAutosizeTextArea";
import { useState } from "react";
import {
  AiOutlineSend,
  AiOutlineCloudUpload,
  AiFillCloseCircle,
} from "react-icons/ai";

// fake changes

export default function MessageInput({
  handleSendMessage,
  handleFileChange,
  handleIconClick,
  fileInputRef,
  previewUrl,
  setPreviewUrl,
  setSelectedFile,
  selectedFile,
  mediaUrlRef,
  messageTextAreaRef,
}) {
  const [input, setInput] = useState("");
  useAutosizeTextArea(messageTextAreaRef.current, input);
  return (
    <form
      disabled={!input && !selectedFile}
      className="flex flex-row flex-1 justify-end fixed bottom-10 w-4/5 max-w-3xl shadow-md"
    >
      <input
        name="file"
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
        className="hidden"
      />

      <AiOutlineCloudUpload
        className="absolute left-2 bottom-5 cursor-pointer text-2xl" // Add cursor-pointer to indicate a clickable icon
        onClick={handleIconClick}
      />
      {previewUrl && (
        <div className="absolute -left-[72px] top-1 h-14 w-16">
          <AiFillCloseCircle
            className={`top-1 left-1 absolute text-2xl text-white bg-gray-900 rounded-full z-10 hover:text-red-500 cursor-pointer`}
            onClick={() => {
              fileInputRef.current.value = ""; // reset file input value, if user tries to upload same file it wouldn't work otherwise
              setPreviewUrl("");
              setSelectedFile(null);
              mediaUrlRef.current = null;
            }}
          />
          <img
            src={previewUrl}
            onError={(e) => {
              e.target.src = "/images/no-preview-small.png";
            }}
            className={`bottom-2 rounded-md object-cover w-full h-full`}
            alt="No Preview"
          />
        </div>
      )}
      <div
        onClick={() => messageTextAreaRef.current.focus()}
        className="w-4/5 h-16 rounded-bl-md border pl-11 px-3 border-gray-300 flex items-center cursor-text"
      >
        <textarea
          name="message"
          className="w-full bg-transparent focus:outline-none max-h-14 resize-none"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          autoComplete="off"
          ref={messageTextAreaRef}
          onKeyDown={(e) => {
            if (
              (e.code === "Enter" || e.code === "NumpadEnter") &&
              !e.shiftKey
            ) {
              handleSendMessage(e, input);
              setInput("");
            }
          }}
        />
      </div>
      <button
        type="submit"
        disabled={!input && !selectedFile}
        onClick={(e) => {
          handleSendMessage(e, input);
          setInput("");
        }}
        className={`w-1/5 h-16  text-white text-lg ${
          (!!input || !!selectedFile) && "hover:text-yellow-100"
        } rounded-br-md bg-gray-900 flex flex-row items-center justify-center`}
      >
        <span>Send</span>
        <AiOutlineSend className="ml-2" />
      </button>
    </form>
  );
}
