import { useCallback, useContext, useEffect, useState } from "react";
import PanelWrapper from "../lowerLevelComponents/PanelWrapper";
import { UserContext } from "context/UserContext";
import { colors } from "theme/colors";
import TextAndButtons from "../lowerLevelComponents/TextAndButtons";
import { Tooltip } from "@mui/material";
import RichTextInput from "components/reusable/RichTextInput";
import { FaXmark } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiSend } from "react-icons/bi";
import { useSnackBar } from "context/SnackBarContext";
import NormalButton from "components/buttons/NormalButton";
import MessageConfirmModal from "../lowerLevelComponents/MessageConfirmModal";
import { massText } from "utils/twilio";
import { httpsCallable, getFunctions } from "firebase/functions";
import CustomerConfirmList from "features/Customers/components/lowerLevelComponents/CustomerConfirmList";
import { getMessagesSentToday } from "utils/firestoreCalls";
// import { functions } from 'utils/firebase';

const shouldCrossOffCustomer = (customer, typeOfMessage) => {
  if (typeOfMessage === "both") return !customer?.email && !customer?.phone;
  else return !customer?.[typeOfMessage];
};

const htmlToText = (html) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
      console.error("Error parsing HTML:", doc.body.firstChild.textContent);
      return html; // Return original HTML if parsing fails
    }
    let text = "";
    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === "BR") {
          text += "\n";
        } else if (node.nodeName === "DIV" || node.nodeName === "P") {
          // Add a newline before block-level elements, but not for the first one
          if (text.length > 0) {
            text += "\n";
          }
        }

        if (node.hasAttribute("data-value")) {
          text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
        } else {
          for (const childNode of node.childNodes) {
            traverse(childNode);
          }
        }

        // Add a newline after block-level elements
        if (
          (node.nodeName === "DIV" || node.nodeName === "P") &&
          node.nextSibling
        ) {
          text += "\n";
        }
      }
    };

    traverse(doc.body);
    return text.trim(); // Trim to remove any leading/trailing whitespace
  } catch (error) {
    console.error("Error in htmlToText:", error);
    return html; // Return original HTML if any error occurs
  }
};

const textToHtml = (text) => {
  return text.replace(/{{(.+?)}}/g, (match, variable) => {
    const variableInfo = DYNAMIC_VARIABLES.find(
      (v) => v.value === `{${variable}}`
    );
    if (variableInfo) {
      return `<span contenteditable="false" class="bg-yellow-50 border border-gray-400 text-gray-700 py-1 px-2 rounded-full cursor-default text-sm whitespace-nowrap" data-value="{${variable}}">${variableInfo.label}<button class="ml-1 text-gray-800 hover:text-gray-400 font-bold text-lg transition-colors duration-300 ease-in-out" data-action="remove">×</button></span>`;
    }
    return match;
  });
};

const DYNAMIC_VARIABLES = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
];
const defaultMessage =
  "Hey {{firstName}}, we're doing a special this month would you like us to service your house again?";

const defaultSubjectLine =
  "Special Offer for You, {{firstName}}, Ready for Your Next Service?";

const handleChangeRichTextChange = (value, setter, textField, htmlField) =>
  setter((prev) => ({
    ...prev,
    [htmlField]: value,
    [textField]: htmlToText(value),
  }));

export default function MultipleCustomersPanel({
  panelVisible,
  setPanelVisible,
  confirmChangePanel,
  showPanelConfirmDialogue,
}) {
  const { userData } = useContext(UserContext);
  const [loadingSend, setLoadingSend] = useState();
  const [message, setMessage] = useState({
    html: textToHtml(defaultMessage),
    text: defaultMessage,
    subjectText: defaultSubjectLine,
    subjectHtml: textToHtml(defaultSubjectLine),
  });
  const [typeOfMessage, setTypeOfMessage] = useState("phoneNumber"); // options: 'phoneNumber', 'email', or 'both'
  const { openSnackBar } = useSnackBar();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [messageSent, setMessageSent] = useState(false);
  const [sendWithLogo, setSendWithLogo] = useState(!!userData?.bizData?.logo);
  const [expanded, setExpanded] = useState(false);

  const handleSendMassMessage = async (e) => {
    e.preventDefault();
    if (loadingSend) return;

    if (!userData?.subData || userData?.subData.status !== "active") {
      alert(
        "You must have an active subscription to send mass text messages. Please contact support if you have any questions."
      );
      return;
    }
    if (typeOfMessage !== "email" && !userData?.bizData?.telnyxNumber) {
      openSnackBar(
        "Your phone number is not set up yet. If it has been more than 24 hours since you purchased a subscription please contact support@homebase360.io."
      );
      return;
    }
    // if the user isn't and admin or a manger or has the explicit permission to send mass marketing messages then return
    if (
      userData?.userData?.userType !== "Admin" &&
      userData?.userData?.userType !== "Manger" &&
      !userData?.userData?.customPermissions?.marketing_messages
    ) {
      openSnackBar(
        "You do not have permission to send marketing messages. Please contact your admin to get access."
      );
      return;
    }
    // if user wants to send texts but has hit their daily limit return, this is also done on the server side
    if (typeOfMessage === "phoneNumber" || typeOfMessage === "both") {
      const messagesSentToday = await getMessagesSentToday(userData.bizData);
      if (messagesSentToday + filteredCustomers.length > 2500) {
        alert(
          "You can not send marketing messages that would exceed your daily limit of 2500 messages (inclusive of individual texts). Please try again tomorrow.\n\nYou can still send individual text messages to your customers."
        );
        return;
      }
    }

    setConfirmationModal(false);
    setLoadingSend(true);

    const responses = [];
    // send texts
    if (typeOfMessage === "phoneNumber" || typeOfMessage === "both") {
      responses.push(
        massText(
          userData.bizData.id,
          message.text,
          filteredCustomers,
          userData.bizData.companyName,
          userData.bizData?.telnyxNumber || ""
        )
      );
    }
    // send emails
    if (typeOfMessage === "email" || typeOfMessage === "both") {
      const functions = getFunctions();
      const massEmailSendFunction = httpsCallable(functions, "massEmailSend");
      responses.push(
        massEmailSendFunction({
          bizData: userData.bizData,
          sendWithLogo,
          subject: message.subjectText,
          body: message.text,
          substitutions: ["{{firstName}}", "{{lastName}}"],
          to: filteredCustomers.map((customer) => ({
            email: customer.email,
            firstName: customer.firstName,
            lastName: customer.lastName,
          })), // remove excess data from request by mapping filtered customers
        })
      );
    }

    try {
      openSnackBar("Sending messages...", false, false, 1200000);
      let responsesData = await Promise.all(responses);
      responsesData = responsesData.map((response) =>
        response.data ? response.data : response
      ); // depending on call the info will either be in the data property or on the surface
      const success = responsesData.reduce((accumBool, response) => {
        return accumBool && (response.success || response.data?.success);
      }, true);

      if (success) {
        openSnackBar(
          "Messages have been scheduled for sending.",
          true,
          false,
          1200000
        );
        setMessageSent(true);
      } else {
        // catch controlled error (they should all be)
        setMessageSent(false);
        // failed sending texts
        if (typeOfMessage === "phoneNumber")
          openSnackBar(
            "There was an error sending your text messages. Please check your internet connection and try again.",
            false,
            true,
            1200000
          );
        // failed sending emails
        else if (typeOfMessage === "email")
          openSnackBar(
            `There was an error sending ${
              responsesData[0].notSent || "an unknown number of"
            } email${
              !responsesData[0].notSent || responsesData[0].notSent > 1
                ? "s"
                : ""
            }. Please check your internet connection and make sure you have the correct email addresses.`,
            false,
            true,
            1200000
          );
        else {
          // failed sending both
          if (!responsesData[0].success && !responsesData[1].success)
            openSnackBar(
              "There was an error sending your text messages and " +
                (responsesData[1].notSent || "an unknown number of") +
                `email${
                  !responsesData[1].notSent || responsesData[1].notSent > 1
                    ? "s"
                    : ""
                }`,
              false,
              true,
              1200000
            );
          // failed sending email messages but texts were sent
          else if (responsesData[0].success && !responsesData[1].success)
            openSnackBar(
              "There was an error sending " +
                (responsesData[1].notSent || "an unknown number of") +
                ` email${
                  !responsesData[1].notSent || responsesData[1].notSent > 1
                    ? "s"
                    : ""
                }, the texts were scheduled for sending.`,
              false,
              true,
              1200000
            );
          // failed seindings texts but emails were sent
          if (!responsesData[0].success && responsesData[1].success)
            openSnackBar(
              "There was an error sending text messages, but emails were sent",
              false,
              true,
              1200000
            );
        }
      }
    } catch (e) {
      console.log("error sending message: ", e);
      setMessageSent(false);
      openSnackBar(
        "There was an error sending some of your messages. Please check your internet connection and try again.",
        false,
        true,
        1200000
      ); // Display the actual error message returned from the backend
    } finally {
      setLoadingSend(false);
    }
  };

  const messageToButtons = [
    {
      label: "phone #",
      onClick: () => {
        setTypeOfMessage("phoneNumber");
      },
    },
    {
      label: "email",
      onClick: () => {
        setTypeOfMessage("email");
      },
    },
    {
      label: "both",
      onClick: () => {
        setTypeOfMessage("both");
      },
    },
  ];

  const customers = panelVisible.data;

  // filter out customers without contact info type
  useEffect(() => {
    setMessageSent(false);
    if (!customers || panelVisible?.type !== "customers") return;
    if (typeOfMessage === "both")
      setFilteredCustomers(
        customers.filter((customer) => customer.phoneNumber || customer.email)
      );
    else
      setFilteredCustomers(
        customers?.filter((customer) => customer[typeOfMessage])
      );
  }, [
    customers,
    setFilteredCustomers,
    typeOfMessage,
    panelVisible,
    setMessageSent,
  ]);

  const crossOffCriteria = (customer) => {
    if (typeOfMessage === "both")
      return !!customer?.email || !!customer?.phone.mobile;
    else if (typeOfMessage === "email") return !!customer?.email;
    else return !!customer.phone.mobile;
  };

  return (
    <>
      {confirmationModal && (
        <MessageConfirmModal
          typeOfMessage={typeOfMessage}
          handleSendMassMessage={handleSendMassMessage}
          setModal={setConfirmationModal}
          filteredCustomers={filteredCustomers}
          message={message}
          openSnackBar={openSnackBar}
          userData={userData}
          sendWithLogo={sendWithLogo}
          setSendWithLogo={setSendWithLogo}
        />
      )}
      <PanelWrapper
        visible={panelVisible?.type === "customers"}
        closeFunction={() => setPanelVisible({ type: "", data: null })}
        title={messageSent ? "Message Sent" : "Selected Customers"}
        width={500}
        confirmChangePanel={confirmChangePanel}
        showPanelConfirmDialogue={showPanelConfirmDialogue}
        expandButton={true}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        {panelVisible?.type === "customers" && (
          <div
            className={`w-full max-w-xl${
              messageSent ? " pointer-events-none opacity-50" : ""
            }`}
          >
            {/* <p className="text-sm">
              {customers?.length || 0} selected, {filteredCustomers.length} with{" "}
              {typeOfMessage === "phoneNumber"
                ? "a phone number"
                : typeOfMessage === "email"
                ? "an email"
                : "an email or number"}
              .
            </p> */}
            {/* customer list */}
            {/* <div className="max-h-56 overflow-y-auto border rounded p-2 text-sm bg-gray-50 mb-6 mt-2">
              {customers?.map((customer, i) => {
                return (
                  <div
                    key={"customer-in-panel" + i}
                    className="flex flex-row items-center mb-1"
                  >
                    <Tooltip disableInteractive title="Remove Customer">
                      <button
                        onClick={() =>
                          setPanelVisible((p) => ({
                            ...p,
                            data: p.data.filter(
                              (el) => el.customerId !== customer.customerId
                            ),
                          }))
                        }
                      >
                        <FaXmark color={colors.red600} />
                      </button>
                    </Tooltip>
                    <p
                      className={`text-nowrap overflow-x-hidden text-ellipsis ml-1 ${
                        shouldCrossOffCustomer(customer, typeOfMessage)
                          ? "line-through"
                          : ""
                      }`}
                    >
                      {customer.name}
                    </p>
                    {customer?.phoneNumber && (
                      <Tooltip disableInteractive title={customer?.phoneNumber}>
                        <button className="cursor-auto">
                          <FaPhone
                            color={colors.gray900}
                            size={12}
                            className="ml-1"
                          />
                        </button>
                      </Tooltip>
                    )}
                    {customer?.email?.length && (
                      <Tooltip disableInteractive title={customer?.email[0]}>
                        <button className="cursor-auto">
                          <MdEmail
                            color={colors.gray900}
                            size={16}
                            className="ml-1"
                          />{" "}
                        </button>
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </div> */}
            <CustomerConfirmList
              customers={customers}
              setCustomers={(customer) =>
                setPanelVisible((p) => ({
                  ...p,
                  data: p.data.filter(
                    (el) => el.customerId !== customer.customerId
                  ),
                }))
              }
              crossOffCriteria={crossOffCriteria}
              contextMessage={
                typeOfMessage === "phoneNumber"
                  ? "with a mobile phone number"
                  : typeOfMessage === "email"
                  ? "with an email"
                  : "with an email or mobile number"
              }
              options={{ emailPath: (customer) => customer.email }}
            />

            {/* subject line */}
            {(typeOfMessage === "email" || typeOfMessage === "both") && (
              <>
                <TextAndButtons
                  title={`Subject Line${
                    typeOfMessage === "both" ? " (email only)" : ""
                  }:`}
                />
                <RichTextInput
                  value={message.subjectHtml}
                  onChange={(value) => {
                    handleChangeRichTextChange(
                      value,
                      setMessage,
                      "subjectText",
                      "subjectHtml"
                    );
                  }}
                  label="Customer Subject"
                  DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
                  LIMIT={120}
                  htmlToText={htmlToText}
                  allowLineBreaks={false}
                />
              </>
            )}

            {/* message  */}
            <TextAndButtons containerStyle="mt-8" title="Message:" />
            <RichTextInput
              value={message.html}
              onChange={(value) => {
                handleChangeRichTextChange(value, setMessage, "text", "html");
              }}
              label="Customer Message"
              DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
              LIMIT={400}
              htmlToText={htmlToText}
            />

            <TextAndButtons
              threeOptionSelect={true}
              buttonData={messageToButtons}
              containerStyle="mb-6 mt-8"
              // disabled={messageSent}
              title="Send Message to:"
              initalSelectedButton={
                typeOfMessage === "phoneNumber"
                  ? 0
                  : typeOfMessage === "email"
                  ? 1
                  : 2
              }
            />

            {/* send button */}
            <div className="w-full flex justify-center mt-10">
              <NormalButton
                onClick={() => {
                  if (!message.text)
                    openSnackBar(
                      "Please enter a message before sending.",
                      false,
                      true
                    );
                  else if (!filteredCustomers.length)
                    openSnackBar(
                      "Please add at least one recipient with contact information.",
                      false,
                      true
                    );
                  else setConfirmationModal(true);
                }}
                loading={loadingSend}
                text={"Send"}
                additionalClasses="w-36 text-lg"
                icon={<BiSend className="text-lg" />}
                iconPosition="right"
              />
            </div>
          </div>
        )}
      </PanelWrapper>
    </>
  );
}
