import { useRef, useCallback, useEffect, useState, useContext } from "react";
import { useToggle } from "hooks/useToggle";
import { AiOutlineEdit } from "react-icons/ai";
import { updateDoc } from "firebase/firestore";
import { batchCancelRecurringJobs } from "../data/api";
import { useUserContext } from "context/UserContext";
import CancelModal from "./CancelModal";
import { SolidButton } from "../../../components/NewButtons/SolidButton";

export const OptionsDropdown = ({
  jobDetails,
  setJobDetails,
  jobRef,
  setDeleteModal,
}) => {
  const { userData } = useUserContext();

  const [editDropdown, setEditDropdown] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  const dropdownRef = useRef(null);
  const editButtonRef = useRef(null);

  const toggle = useToggle();
  const handleEditClick = () => toggle(setEditDropdown);

  const handleClickOutside = useCallback(
    (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(e.target)
      ) {
        setEditDropdown(false);
      }
    },
    [dropdownRef, editButtonRef, setEditDropdown]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const missJob = async () => {
    // update firestore docuement and jobDetails state
    setEditDropdown(false);
    // console.log("cancel job");
    if (jobDetails?.status !== "missed") {
      await updateDoc(jobRef, {
        status: "missed",
        statusBeforeMissed: jobDetails?.status || null,
      });
      setJobDetails({
        ...jobDetails,
        status: "missed",
        statusBeforeMissed: jobDetails?.status || null,
      });
    } else {
      await updateDoc(jobRef, {
        status: jobDetails?.statusBeforeMissed || null,
        statusBeforeMissed: null,
      });
      setJobDetails({
        ...jobDetails,
        status: jobDetails?.statusBeforeMissed || null,
        statusBeforeMissed: null,
      });
    }
  };

  const cancelJob = async () => {
    // update firestore docuement and jobDetails state
    setEditDropdown(false);
    setCancelModal(false);
    // console.log("cancel job");
    if (jobDetails?.status !== "cancelled") {
      await updateDoc(jobRef, {
        status: "cancelled",
        statusBeforeCancel: jobDetails?.status || null,
      });
      setJobDetails({
        ...jobDetails,
        status: "cancelled",
        statusBeforeCancel: jobDetails?.status || null,
      });
    } else {
      await updateDoc(jobRef, {
        status: jobDetails?.statusBeforeCancel || null,
        statusBeforeCancel: null,
      });
      setJobDetails({
        ...jobDetails,
        status: jobDetails?.statusBeforeCancel || null,
        statusBeforeCancel: null,
      });
    }
  };

  const cancelRecurringJobs = async () => {
    await batchCancelRecurringJobs({ jobDetails, userData });
    if (jobDetails?.status !== "cancelled") {
      setJobDetails({
        ...jobDetails,
        status: "cancelled",
        statusBeforeCancel: jobDetails?.status || null,
      });
    } else {
      setJobDetails({
        ...jobDetails,
        status: jobDetails?.statusBeforeCancel || null,
        statusBeforeCancel: null,
      });
    }
    setCancelModal(false);
  };

  return (
    <>
      <div className="relative">
        <SolidButton
          text={"Options"}
          icon={<AiOutlineEdit />}
          innerRef={editButtonRef}
          onClick={handleEditClick}
        />
        {/* <button
        ref={editButtonRef}
        onClick={handleEditClick}
        className="bg-gray-900 rounded-md w-24 px-2 py-3 justify-center shadow-lg flex items-center text-white whitespace-nowrap mr-14 lg:mr-10 hover:text-yellow-100 relative"
        // onClick={() => setCantLeave(false)}
      >
        <AiOutlineEdit className="inline-block mr-2" />
        Options
      </button> */}
        {editDropdown && (
          <div
            className="absolute top-12 w-28 right-0 flex flex-col  bg-white rounded-md shadow-md z-50 divide-y"
            ref={dropdownRef}
          >
            <h1
              className="text-sm text-gray-700 text-center  cursor-pointer py-2 hover:bg-gray-100 rounded-t-md"
              onClick={missJob}
            >
              {jobDetails?.status === "missed"
                ? "Unmark as Missed"
                : "Mark as Missed"}
            </h1>

            <h1
              className="text-sm text-gray-700 text-center  cursor-pointer py-2 hover:bg-gray-100"
              onClick={() => {
                if (jobDetails.isRecurring && jobDetails.recurrenceId) {
                  setCancelModal(true);
                } else {
                  cancelJob();
                }
              }}
            >
              {jobDetails?.status === "cancelled"
                ? "Uncancel Job"
                : "Cancel Job"}
            </h1>
            <h1
              className="text-sm text-gray-700 text-center  cursor-pointer py-2 hover:bg-red-100 rounded-b-md"
              onClick={() => {
                setDeleteModal(true);
                setEditDropdown(false);
              }}
            >
              Delete Job
            </h1>
          </div>
        )}
      </div>
      <CancelModal
        alreadyCancelled={jobDetails?.status === "cancelled"}
        setCancelModal={setCancelModal}
        cancelModal={cancelModal}
        cancelJob={cancelJob}
        cancelRecurringJobs={cancelRecurringJobs}
      />
    </>
  );
};
