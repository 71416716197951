import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../index";
import InputWithLabel from "../AddJob/components/InputWithLabel";
import { ImSpinner } from "react-icons/im";
import { CiCircleQuestion } from "react-icons/ci";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import BasicDropdown from "../../components/reusable/BasicDropdown";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import PhoneInputWithLabel from "../../components/AddCustomer/PhoneInputWithLabel";
import IconButton from "@mui/material/IconButton";
import { IoClose } from "react-icons/io5";
import { httpsCallable, getFunctions } from "firebase/functions";
import { SolidButton } from "components/NewButtons/SolidButton";
import { useSnackBar } from "context/SnackBarContext";

export default function DLCInfoPage() {
  const { userData } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const DLCInfoFromDoc = userData?.bizData?.DLCInfo;
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [legalCompanyName, setLegalCompanyName] = useState(
    DLCInfoFromDoc?.legalCompanyName || ""
  );
  const [DBA, setDBA] = useState(DLCInfoFromDoc?.DBA || "");
  const [EIN, setEIN] = useState(DLCInfoFromDoc?.EIN || "");
  const [EINCountry, setEINCountry] = useState(
    DLCInfoFromDoc?.EINCountry || ""
  );
  const [businessAddress, setBusinessAddress] = useState(
    DLCInfoFromDoc?.businessAddress || ""
  );
  const [email, setEmail] = useState(DLCInfoFromDoc?.email || "");
  const [phone, setPhone] = useState(DLCInfoFromDoc?.phone || "");
  const [website, setWebsite] = useState(DLCInfoFromDoc?.website || "");

  const isDLCRegistered =
    DLCInfoFromDoc?.legalCompanyName &&
    DLCInfoFromDoc?.DBA &&
    DLCInfoFromDoc?.EIN &&
    DLCInfoFromDoc?.EINCountry &&
    DLCInfoFromDoc?.businessAddress &&
    DLCInfoFromDoc?.email &&
    DLCInfoFromDoc?.phone;

  const handleSave = async () => {
    console.log("handle save");
    if (loading) return;
    setLoading(true);

    const DLCInfo = {
      legalCompanyName: legalCompanyName,
      DBA: DBA,
      EIN: EIN,
      EINCountry: EINCountry,
      businessAddress: businessAddress,
      email: email,
      phone: phone,
      website: website,
    };
    try {
      const bizRef = doc(db, "businesses", userData?.userData?.businessId);
      // update biz doc
      await updateDoc(bizRef, {
        DLCInfo: DLCInfo,
      });

      setDirty(false);
      handleOpenSnackBar();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // check that all of the fields are filled out except for website since it is optional
    if (
      legalCompanyName &&
      DBA &&
      EIN &&
      EINCountry &&
      businessAddress &&
      email &&
      phone
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [
    legalCompanyName,
    DBA,
    EIN,
    EINCountry,
    businessAddress,
    email,
    phone,
    website,
  ]);

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };
  const handleOpenSnackBar = () => {
    setSnackBarOpen(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <IoClose />
      </IconButton>
    </React.Fragment>
  );

  // prevent non-admins/managers from accessing this page
  if (
    userData?.userData?.userType !== "Admin" &&
    userData?.userData?.userType !== "Manager"
  )
    return <></>;

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="Information Saved Successfully"
        action={action}
      />
      <div
        className="bg-gray-100 h-full-minus-header
    overflow-y-auto w-full pb-4 flex flex-col items-center"
      >
        <div className="max-w-3xl w-full mt-10">
          <InfoText userData={userData} isDLCRegistered={isDLCRegistered} />
          {/* select and buy a telnyx number */}
          {isDLCRegistered && !userData?.bizData?.telnyxNumber && (
            <NumberPicker userData={userData} openSnackBar={openSnackBar} />
          )}
          {/* fill out 10DLC info */}
          <Form
            dirty={dirty}
            loading={loading}
            handleSave={handleSave}
            legalCompanyName={legalCompanyName}
            setLegalCompanyName={setLegalCompanyName}
            DBA={DBA}
            setDBA={setDBA}
            EIN={EIN}
            setEIN={setEIN}
            EINCountry={EINCountry}
            setEINCountry={setEINCountry}
            businessAddress={businessAddress}
            setBusinessAddress={setBusinessAddress}
            email={email}
            setEmail={setEmail}
            phone={phone}
            isDLCRegistered={isDLCRegistered}
            setPhone={setPhone}
            website={website}
            setWebsite={setWebsite}
          />
        </div>
      </div>
    </>
  );
}
const Form = ({
  dirty,
  loading,
  handleSave,
  legalCompanyName,
  setLegalCompanyName,
  DBA,
  setDBA,
  EIN,
  setEIN,
  EINCountry,
  setEINCountry,
  businessAddress,
  setBusinessAddress,
  email,
  setEmail,
  phone,
  setPhone,
  website,
  setWebsite,
  isDLCRegistered,
}) => {
  return (
    <div
      className={`flex flex-col px-4 py-4 rounded-sm gap-4 items-center mt-10 ${
        isDLCRegistered ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div className="flex flex-col bg-gray-50 shadow-md rounded-md w-full">
        <div className="bg-gray-200 px-4 py-2 rounded-sm flex flex-row justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            10 DLC Information
          </h1>
          {!isDLCRegistered && (
            <button
              disabled={!dirty || loading}
              className={`font-bold w-20 h-10 rounded-md flex justify-center items-center ${
                dirty
                  ? "bg-gray-900  text-white hover:text-yellow-100 "
                  : "bg-gray-300 text-gray-100"
              }`}
              onClick={handleSave}
            >
              {loading ? (
                <ImSpinner className="text-lg animate-spin-slow" />
              ) : (
                "Submit"
              )}
            </button>
          )}
        </div>

        <div className="flex flex-col w-full shrink-0  px-4 py-4 rounded-sm gap-4">
          <div className="flex flex-row items-center">
            <Tooltip title="This is the legal name of your business that matches what is used on your tax returns">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>

            <InputWithLabel
              label="Legal Company Name"
              value={legalCompanyName}
              setValue={setLegalCompanyName}
            />
          </div>

          <div className="flex flex-row items-center">
            <Tooltip title="DBA or Doing Business As. This is the name that is displayed to your customers. If it is the same as your legal company name then please enter that here.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <InputWithLabel
              label="DBA or TradeName"
              value={DBA}
              setValue={setDBA}
            />
          </div>
          <div className="flex flex-row items-center">
            <Tooltip title="Employer Identification Number. Also known as the Federal Employer Identification Number or the Federal Tax Identification Number, is a unique nine-digit number assigned by the IRS. If you are based in Canada then this is your Canadian Business Number.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <InputWithLabel label="EIN" value={EIN} setValue={setEIN} />
          </div>

          <div className="flex flex-row items-center mt-4">
            <Tooltip title="Please select the country in which you registered your business.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <div className="w-48 ml-2">
              <BasicDropdown
                label="EIN Country"
                value={EINCountry}
                setValue={setEINCountry}
                options={[
                  { value: "US", label: "United States" },
                  { value: "CA", label: "Canada" },
                ]}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <Tooltip title="This is the business address that you used when registering your business. Please include the full address. Example: 1234 NW Bobcat Lane, St. Robert, MO 65584 ">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <InputWithLabel
              label="Business Address"
              value={businessAddress}
              setValue={setBusinessAddress}
            />
          </div>
          <div className="flex flex-row items-center">
            <Tooltip title="Email associated with your business that can be used to contact you regarding your 10DLC registration.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <InputWithLabel label="Email" value={email} setValue={setEmail} />
          </div>
          <div className="flex flex-row items-center">
            <Tooltip title="Phone number that can be used to contact you regarding your 10DLC registration.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <PhoneInputWithLabel
              label="Phone"
              value={phone}
              setValue={setPhone}
            />
          </div>
          <div className="flex flex-row items-center">
            <Tooltip title="Please include the full website URL for your business. Example: https://homebase360.io If you do not have a website you can leave this blank as it is optional, but is recommended to make registration easier.">
              <div>
                <CiCircleQuestion className="text-2xl text-gray-900" />
              </div>
            </Tooltip>
            <InputWithLabel
              label="Website"
              value={website}
              setValue={setWebsite}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const InfoText = ({ isDLCRegistered, userData }) => {
  return (
    <div className="mx-8 text-center">
      {isDLCRegistered ? (
        <>
          <h1 className="font-medium text-lg">
            Your company is registered with 10DLC
          </h1>
          <p>
            10DLC allows your business to send text messages to your customers.
          </p>
          {userData?.bizData?.telnyxNumber && (
            <p>
              Assigned number:{" "}
              <strong>{userData?.bizData?.telnyxNumber}</strong>
            </p>
          )}
        </>
      ) : (
        <>
          <h1 className="font-medium text-lg">10DLC Registration Required</h1>
          <p>
            To sending text messages to your customers, businesses must register
            their 10-digit long code (10DLC) phone numbers with carriers.
            Failure to register, will result in message filtering and eventual
            blocking.
          </p>
          <h2 className="font-medium text-lg mt-5">Action Needed:</h2>
          <ol className="list-decimal list-inside">
            <li>
              <strong>Complete the Registration Form Below:</strong> Provide the
              necessary details for us to register your business. The process
              typically takes about 7 days.
            </li>
            <li>
              <strong>Stay Informed:</strong> We'll reach out via email or phone
              if additional information is needed or if there are issues with
              your registration.
            </li>
            <li>
              <strong>Special Note for Sole Proprietors:</strong> If you're
              operating as a sole proprietorship, please contact us directly for
              tailored registration assistance.
            </li>
          </ol>
          <p className="mt-5">
            Questions? Reach out to us at{" "}
            <a href="mailto:support@homebase360.io" className="font-medium">
              support@homebase360.io
            </a>{" "}
            for support.
          </p>
        </>
      )}
    </div>
  );
};
const NumberPicker = ({ userData, openSnackBar }) => {
  const [searchValue, setSearchValue] = useState("");
  const [numberOptions, setNumberOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const functions = getFunctions();

  const searchNumbers = async () => {
    setLoading(true);
    try {
      const findAvailable10DLCNumbers = httpsCallable(
        functions,
        "findAvailable10DLCNumbers"
      );
      const response = await findAvailable10DLCNumbers({
        areaCode: searchValue,
        countryCode: userData.bizData.DLCInfo.EINCountry,
      });

      response.data.forEach((item) => {
        if (item.best_effort) {
          openSnackBar(
            "There was some difficulty retrieving numbers for your search, some are approximate matches."
          );
        }
      });
      if (response.data.length === 0)
        openSnackBar(
          "No numbers were found for your search, please try again."
        );
      setNumberOptions(response.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const purchaseNumber = async (phoneNumber) => {
    try {
      setLoading(true);
      openSnackBar(
        "Purchasing your business phone number, this may take a moment.",
        false,
        false,
        60000
      );
      const purchase10DLCNumber = httpsCallable(
        functions,
        "purchase10DLCNumber"
      );
      await purchase10DLCNumber({
        phoneNumber,
      });
      openSnackBar(
        "Your business phone number is ready! You can start sending text messages.",
        true,
        false,
        60000
      );
    } catch (error) {
      console.log("error: ", error);
      openSnackBar(error.message, false, true, 60000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`flex flex-col px-4 py-4 rounded-sm items-center mt-10 ${
        loading ? "pointer-events-none" : ""
      }`}
    >
      <div className="flex flex-col bg-gray-50 shadow-md rounded-md w-full">
        <div className="bg-gray-200 px-4 py-2 rounded-sm flex flex-row justify-between">
          <h1 className="text-2xl font-bold text-gray-900">
            Select a Business Phone Number
          </h1>
        </div>
        <div className="my-4 gap-4 flex flex-col items-center">
          <p className="text-lg font-medium">
            Search an area code for available 10DLC numbers
          </p>
          <div className="flex flex-row gap-2 mb-2 items-center">
            <InputWithLabel
              label={"Area Code"}
              value={searchValue}
              setValue={setSearchValue}
              onChangeFunction={(e) => {
                if (
                  (!isNaN(e.target.value) && searchValue.length < 3) ||
                  e.nativeEvent.inputType === "deleteContentBackward"
                )
                  setSearchValue(e.target.value);
              }}
            />
          </div>
          <SolidButton
            text="Get Numbers"
            onClick={searchNumbers}
            className="bg-gray-900 text-white px-4 py-2 rounded-md"
            loading={loading}
          />
          <div className={`mt-4 ${numberOptions.length === 0 ? "hidden" : ""}`}>
            <p className="text-red-500 font-bold mb-4">
              Once you select a number it cannot be undone.
            </p>
            {numberOptions.map((option, i) => (
              <div
                key={"number-option-" + i}
                className="flex flex-row gap-4 border-t py-4 items-center"
              >
                <p className="align-self-start w-32">{option.phone_number}</p>
                <div className="align-self-end">
                  <SolidButton
                    text={"Use this number"}
                    onClick={() => purchaseNumber(option.phone_number)}
                    loading={loading}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
