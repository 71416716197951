import React, { useState, useEffect, forwardRef, useMemo } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { useUserContext } from "context/UserContext";
import Header from "../../../ServiceSubscription/components/shared/Header";
import { OutlineButton } from "../../../../components/NewButtons/OutlineButton";
import { SolidButton } from "../../../../components/NewButtons/SolidButton";
import { createInvoicePDF } from "../../../../utils/PDF/InvoiceCreateAndDownload";
import { calcTotalsLineItems } from "utils/calcTotals";
import { InvoicePreviewSettings } from "./InvoicePreviewSettings";
import { fetchInvoice, generateInvoiceNumber } from "../../data/invoice-api";
import SendInvoiceModal from "./SendInvoiceModal";
import AddAttachmentButton from "../../../Estimate/components/AddAttachmentButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { validateAttachments } from "utils/validateAttachments";
import { useSnackBar } from "context/SnackBarContext";
import { handleInvoiceAttachments } from "../../../../utils/firestoreCalls";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

// const invoiceData = useMemo(() => {
//   if (jobDetails && userData)
//     return {
//       // invoiceId: invoiceRef.id,
//       jobId: jobDetails?.jobId,
//       businessId: userData.userData.businessId,
//       businessName: userData.bizData.companyName,
//       businessAddress: userData.bizData.address,
//       businessPhone: userData.bizData.companyPhone,
//       businessEmail: userData.bizData.email,
//       stripeAccountId: userData.bizData.stripeAccountId,
//       // dueDate: serverTimestamp(),
//       dueDate: new Date(),
//       customerId: jobDetails?.customer?.customerId,
//       customerName: jobDetails?.customer?.displayName,
//       customerEmail: jobDetails?.customer?.email,
//       customerPhone: jobDetails?.customer?.phone?.mobile,
//       customerAddress: jobDetails?.customer?.address?.[0],
//       amount: calcTotalsLineItems(jobDetails?.lineItems).total, // amount includes the tax
//       tax: calcTotalsLineItems(jobDetails?.lineItems).tax,
//       // status: "sent",
//       billingType: "digital invoice",
//       lineItems: jobDetails?.lineItems,
//       serviceDate: jobDetails?.start,
//       // invoiceSentTime: serverTimestamp(),
//       invoiceSentTime: new Date(),
//       numberOfTimesSent: 1,
//       invoicePaid: false,
//       currency: userData.bizData?.currency,
//       invoiceSettings: userData.bizData?.invoiceSettings || null,
//     };
// }, [jobDetails, userData]);

// useEffect(() => {
//   if (jobDetails)
//     setInvoiceDetails((curInvoiceDetails) => {
//       return {
//         ...curInvoiceDetails,
//         jobId: jobDetails?.jobId,
//         businessId: userData.userData.businessId,
//         businessName: userData.bizData.companyName,
//         businessAddress: userData.bizData.address,
//         businessPhone: userData.bizData.companyPhone,
//         businessEmail: userData.bizData.email,
//         stripeAccountId: userData.bizData.stripeAccountId,
//         // dueDate: serverTimestamp(),
//         // dueDate: new Date(),
//         customerId: jobDetails?.customer?.customerId,
//         customerName: jobDetails?.customer?.displayName,
//         customerEmail: jobDetails?.customer?.email,
//         customerPhone: jobDetails?.customer?.phone?.mobile,
//         customerAddress: jobDetails?.customer?.address?.[0],
//         amount: calcTotalsLineItems(jobDetails?.lineItems).total, // amount includes the tax
//         tax: calcTotalsLineItems(jobDetails?.lineItems).tax,
//         lineItems: jobDetails?.lineItems,
//         serviceDate: jobDetails?.start,
//         // status: "sent",
//         // billingType: "digital invoice",

//         // invoiceSentTime: serverTimestamp(),
//         // invoiceSentTime: new Date(),
//         // numberOfTimesSent: 1,
//         // invoicePaid: false,
//         // currency: userData.bizData?.currency,
//         // invoiceSettings: userData.bizData?.invoiceSettings,
//         // onInvoiceMessage: userData.bizData?.onInvoiceMessage,
//       };
//     });
// }, [jobDetails, userData]);

export default function PreviewInvoiceModal({ open, onClose, jobDetails }) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const [pdfFile, setPdfFile] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState({
    jobId: jobDetails?.jobId,
    businessId: userData.userData.businessId,
    businessName: userData.bizData.companyName,
    businessAddress: userData.bizData.address,
    businessPhone: userData.bizData.companyPhone,
    businessEmail: userData.bizData.email,
    stripeAccountId: userData.bizData.stripeAccountId,
    // dueDate: serverTimestamp(),
    dueDate: new Date(),
    customerId: jobDetails?.customer?.customerId,
    customerName: jobDetails?.customer?.displayName,
    customerEmail: jobDetails?.customer?.email,
    customerPhone: jobDetails?.customer?.phone?.mobile,
    customerAddress: jobDetails?.customer?.address?.[0],
    amount: calcTotalsLineItems(jobDetails?.lineItems).total, // amount includes the tax
    tax: calcTotalsLineItems(jobDetails?.lineItems).tax,
    billingType: "digital invoice",
    lineItems: jobDetails?.lineItems,
    serviceDate: jobDetails?.start,
  });
  const [invoiceSettings, setInvoiceSettings] = useState(
    userData.bizData?.invoiceSettings
  );
  const [attachments, setAttachments] = useState([]);

  const [sendingInvoice, setSendingInvoice] = useState(false);

  useEffect(() => {
    setInvoiceDetails((curInvoice) => ({
      ...curInvoice,
      businessId: userData.userData.businessId,
      businessName: userData.bizData.companyName,
      businessAddress: userData.bizData.address,
      businessPhone: userData.bizData.companyPhone,
      businessEmail: userData.bizData.email,
      stripeAccountId: userData.bizData.stripeAccountId,
      customerId: jobDetails?.customer?.customerId,
      customerName: jobDetails?.customer?.displayName,
      customerEmail: jobDetails?.customer?.email,
      customerPhone: jobDetails?.customer?.phone?.mobile,
      customerAddress: jobDetails?.customer?.address?.[0],
      amount: calcTotalsLineItems(jobDetails?.lineItems).total, // amount includes the tax
      tax: calcTotalsLineItems(jobDetails?.lineItems).tax,
      lineItems: jobDetails?.lineItems,
      serviceDate: jobDetails?.start,
      currency: userData.bizData?.currency,
    }));
  }, [jobDetails, userData]);

  useEffect(() => {
    if (jobDetails.invoiceId) {
      fetchInvoice({
        businessId: userData.userData.businessId,
        invoiceId: jobDetails?.invoiceId,
      }).then((fetchedInvoice) => {
        setInvoiceDetails((curInvoiceDetails) => ({
          // Fields that are outdated will be overwritten and the ones that don't need to be overwritten do not exist on the current invoiceDetails
          ...fetchedInvoice,
          ...curInvoiceDetails,
        }));
        if (fetchedInvoice.attachments)
          setAttachments(fetchedInvoice.attachments);
        if (fetchedInvoice.invoiceSettings)
          setInvoiceSettings(fetchedInvoice.invoiceSettings);
      });
    } else {
      generateInvoiceNumber({ businessId: userData.userData.businessId }).then(
        (invoiceNumber) => {
          setInvoiceDetails((curInvoiceDetails) => ({
            ...curInvoiceDetails,
            invoiceNumber,
            // These do not yet exist on the initial invoice
            billingType: "digital invoice",
            invoicePaid: false,
            currency: userData.bizData?.currency,

            // Will instead be updated on server once sent
            // status: "sent",
            // invoiceSentTime: new Date(),
            // numberOfTimesSent: 1,
          }));
        }
      );
    }
  }, []);

  useEffect(() => {
    const fetchInvoicePDF = async () => {
      if (invoiceDetails) {
        const pdf = await createInvoicePDF(
          { ...invoiceDetails, invoiceSettings },
          userData?.bizData
        );
        pdf.getDataUrl((url) => {
          setPdfFile(url);
        });
      }
    };

    fetchInvoicePDF();
  }, [invoiceDetails, invoiceSettings, userData?.bizData]);

  const removeAttachment = (index) => {
    setAttachments((curAttachments) =>
      curAttachments.filter((_, i) => i !== index)
    );
  };

  const invoiceData = useMemo(() => {
    return { ...invoiceDetails, invoiceSettings, attachments };
  }, [invoiceDetails, invoiceSettings, attachments]);
  return (
    <>
      <Modal open={open} onClose={() => {}}>
        <TransitionComponent in={open}>
          <div
            className={`absolute top-0 right-0 h-full items-center  md:rounded-tl-md md:rounded-bl-md flex flex-col outline-none w-full md:w-3/5 bg-white`}
          >
            <Header title="Preview and Send Invoice" onClose={onClose} />
            {/* Content */}
            <div className="flex-1 w-full bg-gray-100 overflow-scroll">
              <div className="flex flex-col lg:flex-row items-center lg:items-start p-4 gap-4 lg:gap-8 justify-center ">
                <div className="w-full max-w-xl">
                  <object
                    data={pdfFile}
                    type="application/pdf"
                    name="Invoice PDF"
                    width="100%"
                    height="100%"
                    className=" bg-white shadow-bold w-full relative min-h-[80vh]"
                  >
                    <p></p>
                  </object>
                </div>
                <div className="w-full lg:w-96">
                  <div className="mb-4">
                    <div className="flex flex-row gap-2">
                      <AddAttachmentButton
                        attachments={attachments}
                        setAttachments={setAttachments}
                      />
                    </div>
                    {attachments.length > 0 && (
                      <div>
                        <div className="pt-4 pb-0">
                          <h2 className="text-xl font-semibold">Attachments</h2>
                        </div>
                        <div className="px-0">
                          {attachments.map((attachment, index) => (
                            <div
                              className="flex flex-row gap-2 items-center"
                              key={index}
                            >
                              <button onClick={() => removeAttachment(index)}>
                                <CancelIcon
                                  className="text-gray-500 hover:text-gray-600 h-8"
                                  fontSize="small"
                                />
                              </button>
                              <p className="text-gray-500 truncate leading-8">
                                {attachment.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {invoiceSettings && (
                    <InvoicePreviewSettings
                      invoiceSettings={invoiceSettings}
                      setInvoiceSettings={setInvoiceSettings}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className="px-6 h-16 min-h-16 w-full flex flex-row items-center justify-between border-t border-gray-300 ">
              <OutlineButton text="Cancel" onClick={onClose} />
              <SolidButton
                text="Send Invoice"
                loading={uploadingFiles}
                onClick={async () => {
                  setUploadingFiles(true);
                  try {
                    validateAttachments(attachments);
                    const uploadedAttachments = await handleInvoiceAttachments(
                      attachments
                    );
                    setAttachments(uploadedAttachments);
                    setSendingInvoice(true);
                  } catch (error) {
                    if (error.message.includes("exceed the")) {
                      openSnackBar(error.message, false, true);
                    } else {
                      openSnackBar("Error processing attachments", false, true);
                    }
                  }
                  setUploadingFiles(false);
                }}
              />
            </div>
          </div>
        </TransitionComponent>
      </Modal>
      {sendingInvoice && (
        <SendInvoiceModal
          open={sendingInvoice}
          setOpen={setSendingInvoice}
          invoiceDetails={invoiceData}
          jobDetails={jobDetails}
          closePreviewModal={onClose}
        />
      )}
    </>
  );
}
