import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const importCustomers = httpsCallable(functions, "importCustomers");
export const fetchPublicEstimateDetails = httpsCallable(
  functions,
  "fetchPublicEstimateDetails"
);
export const updatePublicEstimateStatus = httpsCallable(
  functions,
  "updatePublicEstimateStatus"
);
export const sendInvoiceOnCall = httpsCallable(functions, "sendInvoiceOnCall");
export const sendEstimateOnCall = httpsCallable(
  functions,
  "sendEstimateOnCall"
);

export const fetchPublicInvoiceDetails = httpsCallable(
  functions,
  "fetchPublicInvoiceDetails"
);
