import { UserContext } from "context/UserContext";
import { useState, useContext, useCallback, useRef, useEffect } from "react";
import { createInitials } from "utils/helpers";

// this hook returns a state (optimistic message state), setState (add optimistic messages), and removeOptimisticMessage (call this to remove a message)
const useOptimisticMessages = ({ customerNumber, bottomMessageElement }) => {
  const { userData } = useContext(UserContext);
  const [optimisticMessages, setOptimisticMessages] = useState([]);
  const scrollToBottomRef = useRef(false);

  // anytime the optimistic messages change, scroll to the bottom if needed (this should happen whenever a new message is added, but not updated or deleted)
  useEffect(() => {
    if (scrollToBottomRef.current) {
      bottomMessageElement.current.scrollIntoView({ behavior: "smooth" });
      scrollToBottomRef.current = false;
    }
  }, [optimisticMessages, bottomMessageElement]);

  // find the oldest message, or most recent with the same text/imageUrl and remove it
  const removeOptimisticMessage = useCallback(
    ({ text, imageUrl = null, removeOldestMessage = true }) => {
      setOptimisticMessages((p) => {
        let found = false;
        const filteredMesasges = removeOldestMessage
          ? p
              .reverse()
              .filter((el) => {
                if (el.text !== text || imageUrl !== el.imageUrl || found)
                  return true;
                found = true;
                return false;
              })
              .reverse()
          : p.filter((el) => {
              if (el.text !== text || imageUrl !== el.imageUrl || found)
                return true;
              found = true;
              return false;
            });
        return filteredMesasges;
      });
    },
    [setOptimisticMessages]
  );

  // create a copy of the message that only exists on the client, until the real one is synced from the DB
  const addOptimisticMessage = ({ text, imageUrl, scrollToBottom = true }) => {
    if (scrollToBottom) scrollToBottomRef.current = true;
    setOptimisticMessages((p) => {
      if (imageUrl?.then) {
        // if the imageUrl is a promise we want to update it once the promise resolves/image is uploaded
        imageUrl?.then((uploadedImageUrl) => {
          removeOptimisticMessage({
            text,
            imageUrl,
            removeOldestMessage: false,
          });
          addOptimisticMessage({
            text,
            imageUrl: uploadedImageUrl,
            scrollToBottom: false,
          });
        });
      }
      return [
        {
          _id: `optimistic-message-${Date.now()}`,
          createdAt: null, // this message hasn't actually been "created" yet, so we don't set a createdAt. This also helps us display it properly
          from: userData.bizData.telnyxNumber,
          imageUrl: imageUrl || null, // image url needs to be null if it's not provided to match the server message
          telnyxMessageId: `optimistic-message-telnyx-id-${Date.now()}`,
          text,
          to: customerNumber,
          user: {
            _id: userData.bizData.id,
            initials: createInitials(userData.bizData.companyName),
            name: userData.bizData.companyName,
          },
        },
        ...p,
      ];
    });
  };

  return [optimisticMessages, addOptimisticMessage, removeOptimisticMessage];
};

export default useOptimisticMessages;
