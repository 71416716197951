import React, { useState, useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { createReceiptPdf } from "../../utils/CreateReceipt";
import RenderLineItems from "components/payments/InvoiceComponents/RenderLineItems";

export default function Success({
  paymentMethod,
  paymentIntent,
  invoice,
  downloadInvoice,
  serviceDate,
  invoiceNumber,
  amountInDollars,
}) {
  // state for conditional rendering of css
  const [isHovered, setIsHovered] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(false);

  async function downloadReceipt() {
    await createReceiptPdf(invoice);
  }

  let paymentMethodBrand = "Card";
  if (paymentMethod?.card?.brand) {
    paymentMethodBrand =
      paymentMethod?.card?.brand.slice(0, 1).toUpperCase() +
      paymentMethod?.card.brand.slice(1);
  }

  let lastFour = "";
  if (paymentMethod?.card?.last4) {
    lastFour = paymentMethod?.card?.last4;
  }

  return (
    <div className="flex flex-col bg-slate-100/90 min-h-screen h-full overflow-hidden items-center font-stripe">
      <div className="flex flex-col mt-16 mb-24 w-96 sm:w-[420px]">
        <h1 className="font-semibold text-2xl text-slate-600 pl-1 ">
          {invoice.businessName}
        </h1>
        <div className="flex flex-col bg-slate-50 w-full rounded-lg shadow-2xl mt-5 items-center ">
          <div className="mx-auto mt-8 text-sm text-slate-600 relative">
            <div className="w-16 h-20 border-2 rounded-md shadow-lg ">
              <div className="flex flex-row mt-2  items-center mx-1">
                <div className="w-2 bg-slate-300/90 h-2 rounded-full mr-1"></div>

                <div className="w-6 bg-slate-200 h-1 rounded-md "></div>
              </div>
              <div className="flex flex-col space-y-1.5 mt-3 mx-1">
                <div className="w-3/5 bg-slate-200/60 h-1 rounded-md "></div>
                <div className="w-3/5 bg-slate-200 h-1 rounded-md "></div>
              </div>
              <div className="flex flex-col space-y-1.5 mt-3 mx-1">
                <div className="flex flex-row">
                  <div className="w-2/5 bg-slate-300/80 h-1 rounded-md mr-2 "></div>
                  <div className="w-2/5 bg-slate-300/80 h-1 rounded-md"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-2/5 bg-slate-200 h-1 rounded-md mr-2 "></div>
                  <div className="w-2/5 bg-slate-200 h-1 rounded-md"></div>
                </div>
              </div>
            </div>{" "}
            <div
              className="absolute text-2xl text-green-600  z-10 rounded-full shadow-sm"
              style={{
                bottom: "-8px",
                right: "-8px",
              }}
            >
              <BsFillCheckCircleFill />{" "}
            </div>
          </div>
          <h1 className="text-slate-500 pt-8 font-medium ">Invoice paid</h1>
          <h1 className="text-slate-700 text-lg font-bold pt-1 ">
            Amount due ${(0).toFixed(2)}
          </h1>

          <h1
            className="text-slate-500 text-sm font-medium ml-2 pt-2 flex flex-row items-center hover:text-slate-800 cursor-pointer"
            onClick={() => setInvoiceDetails(!invoiceDetails)}
          >
            {invoiceDetails ? (
              <>
                <span className="mr-2">Close invoice details</span>{" "}
                <IoMdClose />
              </>
            ) : (
              <>
                <span className="mr-2">View invoice details</span>{" "}
                <BsChevronDown />
              </>
            )}
          </h1>

          <h1 className="pt-8 text-sm text-slate-500 flex flex-row  w-4/5 justify-between ">
            <span className="text-slate-500/80">Invoice</span>
            <span className="font-medium ">{invoiceNumber}</span>
          </h1>
          <h1 className="pt-1 text-sm text-slate-500 flex flex-row  w-4/5 justify-between ">
            <span className="text-slate-500/80 ">Payment date</span>
            <span className="font-medium">
              {format(new Date(), "MMMM dd, yyyy")}
            </span>
          </h1>
          <h1 className="pt-1 text-sm text-slate-500 flex flex-row mb-8 w-4/5 justify-between items-center ">
            <span className="text-slate-500/80 ">Payment method</span>
            <span className="font-medium flex flex-row  items-center">
              <span className="mr-1">{paymentMethodBrand}</span>
              •••• <span className="text-md ml-1">{lastFour}</span>
            </span>
          </h1>
          <div className="flex flex-row justify-between items-center gap-3 w-4/5 mb-8">
            <button
              className="flex flex-row items-center justify-center   font-medium text-sm text-sky-600/90 border-sky-600/90 border w-1/2 h-9 rounded-md hover:shadow-md"
              onClick={downloadInvoice}
            >
              Download invoice
            </button>
            <button
              className="flex flex-row items-center justify-center text-center bg-sky-600/90 text-slate-50 font-medium text-sm w-1/2 h-9 rounded-md hover:shadow-md"
              onClick={() => downloadReceipt()}
            >
              Download receipt
            </button>
          </div>

          <div
            className={`flex flex-col w-full space-y-2  ${
              invoiceDetails ? "open" : "dropdown"
            }`}
          >
            <div
              className={`flex flex-row justify-between mt-5 ${
                invoiceDetails ? "fade-in" : "fade-out"
              } `}
            >
              <h1 className="text-slate-500 text-xl w-1/2 font-medium ml-7">
                ${amountInDollars.toFixed(2)}
              </h1>
              <div className="flex flex-col text-slate-500 w-3/5 text-sm space-y-1">
                <div className="flex flex-row">
                  <h1 className="w-1/2">Invoice</h1>
                  <h1 className="w-1/2">{invoiceNumber}</h1>
                </div>
                <div className="flex flex-row">
                  <h1 className="w-1/2">Service Date</h1>
                  <h1 className="w-1/2">
                    {serviceDate ? format(serviceDate, "MMM dd, yyyy") : ""}
                  </h1>
                </div>
                <div className="flex flex-row ">
                  <h1 className="w-1/2">Paid</h1>
                  <h1 className="w-1/2">
                    {invoice?.datePaid?.seconds
                      ? format(
                          new Date(invoice.datePaid.seconds * 1000),
                          "MMM dd, yyyy"
                        )
                      : format(new Date(), "MMM dd, yyyy")}
                  </h1>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-row justify-between pt-16 ${
                invoiceDetails ? "fade-in" : "fade-out"
              } `}
            >
              <h1 className="text-slate-500 text-xl w-1/2 font-medium ml-7"></h1>
              <div className="flex flex-col text-slate-500 w-3/5 text-sm space-y-1">
                <h1 className="w-full">{invoice.businessName}</h1>
                <h1 className="w-full">
                  {"(" +
                    invoice.businessPhone.substring(2, 5) +
                    ") " +
                    invoice.businessPhone.substring(5, 8) +
                    "-" +
                    invoice.businessPhone.substring(8, 12)}
                </h1>

                <h1 className="w-full"> {invoice.businessEmail}</h1>
                <h1 className="w-full">{invoice.businessAddress}</h1>
              </div>
            </div>
            <RenderLineItems
              invoice={invoice}
              invoiceDetails={invoiceDetails}
            />
            {/* <div className="pt-20 pb-4 border-b mr-2">
              {invoice.lineItems &&
                invoice.lineItems.length > 0 &&
                invoice.lineItems.map((item, key) => {
                  const unitPrice = parseFloat(item.unitPrice);
                  const quantity = parseFloat(item.quantity);
                  const itemSubTotal = unitPrice * quantity;
                  const taxRate = parseFloat(item.taxRate?.rate) || 0;
                  const itemTax = itemSubTotal * (taxRate / 100);

                  return (
                    <div
                      className={`flex flex-row justify-between pb-2 ${
                        invoiceDetails ? "fade-in" : "fade-out"
                      } `}
                      key={key}
                    >
                      <div className="flex flex-col w-1/2">
                        <h1 className="text-slate-500 text-md  pl-7">
                          {item.name}
                        </h1>
                        {itemTax > 0 && (
                          <h1 className="text-slate-500 text-sm  pl-7">Tax</h1>
                        )}

                        <h1 className="text-slate-400/90 text-sm w-1/2 pl-7 ">
                          Qty {item.quantity}
                        </h1>
                      </div>
                      <div className="flex flex-col text-end">
                        <h1 className="text-slate-500 text-md pr-3">
                          $
                          {item.unitPrice &&
                            parseFloat(item.unitPrice).toFixed(2)}
                        </h1>
                        {itemTax > 0 && (
                          <h1 className="text-slate-500 text-md pr-3">
                            ${itemTax.toFixed(2)}
                          </h1>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div> */}
            <div
              className={`flex flex-row justify-between pt-2 items-center pb-8 ${
                invoiceDetails ? "fade-in-last" : "fade-out"
              } `}
            >
              <div className="flex flex-col w-1/2">
                <h1 className="text-slate-500 text-md  pl-7 font-bold">
                  Amount due
                </h1>
              </div>
              <h1 className="text-slate-500 text-md pr-3 text-lg font-bold">
                ${(0).toFixed(2)}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-gray-400 text-xs sticky w-full text-center"
        style={{
          bottom: "40px",
        }}
      >
        Powered by{" "}
        <span className="font-semibold text-gray-500/90">Homebase360</span> and{" "}
        <span className="font-semibold text-gray-500/90 cursor-pointer">
          <Link to="https:/stipe.com/invoices">Stripe</Link>
        </span>
      </div>
    </div>
  );
}
