import { db } from "utils/firebase";
import {
  collection,
  query,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  deleteDoc,
  where,
  serverTimestamp,
  increment,
  writeBatch,
} from "firebase/firestore";
import { sendMessage } from "../../../utils/twilio";
import { calcTotalsLineItems } from "../../../utils/calcTotals";

export async function fetchJobDetails({ businessId, jobId }) {
  const jobRef = doc(db, "businesses", businessId, "jobs", jobId);
  const job = await getDoc(jobRef);
  const jobData = job.data();
  if (job.exists() && jobData) {
    // console.log("raw job data", job.data());
    // Need to format the dates from the db
    return {
      ...jobData,
      start: jobData?.start?.toDate() ?? null,
      end: jobData?.end?.toDate() ?? null,
      startDrivingTime: jobData?.startDrivingTime?.toDate() ?? null,
      endDrivingTime: jobData?.endDrivingTime?.toDate() ?? null,
      startedJobTime: jobData?.startedJobTime?.toDate() ?? null,
      endedJobTime: jobData?.endedJobTime?.toDate() ?? null,
      invoiceSentTime: jobData?.invoiceSentTime?.toDate() ?? null,
      invoicePaidTime: jobData?.invoicePaidTime?.toDate() ?? null,
      datePaid: jobData?.datePaid?.toDate() ?? null,
      reviewMessageSent: jobData?.reviewMessageSent?.toDate() ?? null,
      reviewSubmitted: jobData?.reviewSubmitted?.toDate() ?? null,
      recurrenceStartDate: jobData?.recurrenceStartDate?.toDate() ?? null,
      recurrenceEndDate: jobData?.recurrenceEndDate?.toDate() ?? null,
    };

    // Sync job customer with customer in database --> this makes me nervous so we moved it to a seperate useEffect
    // updateCustomer(jobData?.customer?.customerId);
  } else {
    console.log("no such job");
    return null;
  }
}

// this has been deprecated in favor of useSubscriptionsJobDetails (which is a custom hook that implements real time listeners)
export const fetchSubscriptionsAndSelectedSubscription = async ({
  businessId,
  customerId,
  subscription,
}) => {
  if (!businessId || !customerId) return null;
  let selectedSubscription = null;
  const subscriptionId =
    subscription?.subscriptionId ||
    subscription?.selectedSubscription?.subscriptionId;

  if (subscriptionId) {
    const subscriptionRef = doc(
      db,
      "businesses",
      businessId,
      "subscriptions",
      subscriptionId
    );
    const subscriptionData = await getDoc(subscriptionRef);
    if (subscriptionData.exists()) {
      selectedSubscription = subscriptionData.data();
    }
  }

  let associatedSubscriptionsToCustomer = null;
  const subscriptionsRef = collection(
    db,
    "businesses",
    businessId,
    "subscriptions"
  );
  const subscriptionsQuery = query(
    subscriptionsRef,
    where("customer.customerId", "==", customerId)
  );
  const querySnapshot = await getDocs(subscriptionsQuery);
  if (!querySnapshot.empty) {
    associatedSubscriptionsToCustomer = querySnapshot.docs.map((doc) =>
      doc.data()
    );
    if (!selectedSubscription) {
      // if there isn't a selected subscription we set it to the first one.. so that it is displayed so they can add a visit to their job if they want to (important)
      selectedSubscription = associatedSubscriptionsToCustomer[0];
    }
  }

  let selectedVisit = subscription?.selectedVisit || null;

  return {
    selectedSubscription,
    subscriptions: associatedSubscriptionsToCustomer,
    selectedVisit,
  };
};

export const getAndUpdateCustomer = async ({
  customerId,
  userData,
  jobRef,
}) => {
  // console.log("Fetching customer from DB");
  // console.log("customerId", customerId);
  try {
    if (!customerId) return null;
    const customerRef = doc(
      db,
      "businesses",
      userData.userData.businessId,
      "customers",
      customerId
    );
    const customer = await getDoc(customerRef);
    if (customer.exists()) {
      await updateDoc(jobRef, {
        customer: {
          ...customer.data(),
        },
      });
      return customer.data();
    } else {
      console.log("no such customer");
      return null;
    }
  } catch (err) {
    console.log("Error", err);
  }
};

export const sendMessageRouteStart = async ({ userData, jobDetails }) => {
  try {
    const message =
      userData?.bizData?.onDriveStartMessage ||
      `Your technician is on their way to your location. \n\n- ${userData.bizData?.companyName}`;
    const to = jobDetails?.customer?.phone?.mobile;
    const from = userData.bizData?.twilioNumber;
    const businessId = userData.bizData?.id;
    const companyName = userData.bizData?.companyName;
    const customerName = jobDetails?.customer?.displayName;
    const notifications = jobDetails?.customer?.notifications;

    if (to && businessId && companyName && customerName && notifications) {
      return sendMessage(
        message,
        to,
        from,
        businessId,
        companyName,
        customerName
      );
    } else {
      alert("missing data or notifications turned off");
    }
  } catch (err) {
    console.error("Error:", err);
  }
};

export const batchDeleteRecurringJobs = async ({ userData, jobDetails }) => {
  const dbQuery = query(
    collection(db, "businesses", userData.userData.businessId, "jobs"),
    where("recurrenceId", "==", jobDetails.recurrenceId),
    where("start", ">=", jobDetails.start)
  );
  const querySnapshot = await getDocs(dbQuery);
  // Check if there are any documents to delete
  if (querySnapshot.empty) {
    console.log("No documents found with the provided recurrenceId.");
    return;
  }
  const batch = writeBatch(db);
  querySnapshot.forEach((docSnapshot) => {
    const docRef = doc(
      db,
      "businesses",
      userData.userData.businessId,
      "jobs",
      docSnapshot.id
    );
    batch.delete(docRef);
  });
  // Commit the batch
  await batch.commit();
  console.log(
    "All recurring jobs with the specified recurrenceId have been deleted."
  );
};

export const batchCancelRecurringJobs = async ({ userData, jobDetails }) => {
  try {
    // cancel all jobs with matching recurrenceId
    // fetch all jobs with matching recurrenceId
    const dbQuery = query(
      collection(db, "businesses", userData.userData.businessId, "jobs"),
      where("recurrenceId", "==", jobDetails.recurrenceId)
    );
    const querySnapshot = await getDocs(dbQuery);
    // Check if there are any documents to delete
    if (querySnapshot.empty) {
      console.log("No documents found with the provided recurrenceId.");
      return;
    }
    const batch = writeBatch(db);
    querySnapshot.forEach((docSnapshot) => {
      const docRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "jobs",
        docSnapshot.id
      );
      if (jobDetails?.status !== "cancelled") {
        batch.update(docRef, {
          status: "cancelled",
          statusBeforeCancel: docSnapshot.data().status || null,
        });
      } else {
        batch.update(docRef, {
          status: docSnapshot.data().statusBeforeCancel || null,
          statusBeforeCancel: null,
        });
      }
    });
    // Commit the batch
    await batch.commit();
    console.log(
      "All recurring jobs with the specified recurrenceId have been cancelled."
    );
  } catch (err) {
    console.error("Error", err);
  }
};

export const toggleNotificationsFirestore = async ({
  jobDetails,
  userData,
  jobRef,
}) => {
  // update job and customer firestore document and jobDetails state
  // console.log("toggle notifications");
  const newNotificationValue = !jobDetails.customer.notifications;

  if (jobDetails?.isRecurring && jobDetails?.recurrenceId) {
    try {
      // get all jobs with matching recurrenceId
      const dbQuery = query(
        collection(db, "businesses", userData.userData.businessId, "jobs"),
        where("recurrenceId", "==", jobDetails.recurrenceId)
      );
      const querySnapshot = await getDocs(dbQuery);
      // Check if there are any documents to update, if there are then we batch update all of them
      if (!querySnapshot.empty) {
        const batch = writeBatch(db);
        querySnapshot.forEach((docSnapshot) => {
          const docRef = doc(
            db,
            "businesses",
            userData.userData.businessId,
            "jobs",
            docSnapshot.id
          );
          batch.update(docRef, {
            customer: {
              ...jobDetails.customer,
              notifications: newNotificationValue,
            },
          });
        });
        // Commit the batch
        await batch.commit();
        console.log("batch update complete");
      }

      // also update the customer document
      const customerRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "customers",
        jobDetails.customer.customerId
      );
      await updateDoc(customerRef, {
        notifications: newNotificationValue,
      });

      console.log("updated customer notifications for job and customer");
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      await updateDoc(jobRef, {
        customer: {
          ...jobDetails.customer,
          notifications: newNotificationValue,
        },
      });
      // also update the customer document
      const customerRef = doc(
        db,
        "businesses",
        userData.userData.businessId,
        "customers",
        jobDetails.customer.customerId
      );
      await updateDoc(customerRef, {
        notifications: newNotificationValue,
      });
      console.log("updated customer notifications for job and customer");
    } catch (error) {
      console.log(error);
      // Revert the state in case of an error

      // Notify the user about the error (you can use a more user-friendly message)
      alert("Failed to update notifications. Please try again.");
    }
  }
};
