import React, { useState, useEffect, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";
import algoliasearch from "algoliasearch";
import { useUserContext } from "context/UserContext";
import { debounce } from "lodash";

export default function SearchConversations({
  label,
  inputValue,
  setInputValue,
  conversations,
  setFilteredConversations,
  ...props
}) {
  const { userData } = useUserContext();
  const inputRef = useRef("");

  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );

  const index = client.initIndex("conversations");
  const handleAlgoliaSearch = debounce(async (text) => {
    if (!inputValue) return;
    const businessId = userData.userData.businessId;

    try {
      const { hits } = await index.search(text, {
        filters: `bizId:${businessId}`,
        hitsPerPage: 5,
      });
      if (inputRef.current.length > 0) setFilteredConversations(hits); // only update results if there's currently a search input (prevent a race condition)
    } catch (err) {
      console.error("Error with search", err);
    }
  }, 200);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    inputRef.current = event.target.value;
    if (event.target.value) {
      handleAlgoliaSearch(event.target.value);
    } else {
      setFilteredConversations(conversations);
    }
  };

  // set initial filtered conversations to be all conversations
  useEffect(() => {
    setFilteredConversations(conversations);
  }, [conversations, setFilteredConversations]);

  return (
    <div className="relative w-1/2 flex flex-col " {...props}>
      <input
        className="bg-gray-50 border border-gray-300 rounded-md outline-none focus:border-gray-400 text-base font-thin py-1 pl-9"
        value={inputValue}
        placeholder={label}
        onChange={handleChange}
      />
      <BiSearchAlt2
        className={`absolute top-1.5 left-2 text-2xl text-gray-400  ${
          inputValue && "text-gray-600"
        }`}
      />
    </div>
  );
}
