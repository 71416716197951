import React, { useEffect, useState } from "react";
import {
  useStripe,
  CardElement,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { storage } from "../../utils/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { BiLoader } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { BiLoaderAlt } from "react-icons/bi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { node } from "../../constants/constants";
import { Link } from "react-router-dom";
import {
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { format } from "date-fns";
import Success from "./Success";
import EnterTip from "./EnterTip";
// import PaymentIntentData from "./PaymentIntentData";
import { createStripeCustomer } from "utils/stripeConnect";
import { CheckboxInvoice } from "components/ui/checkboxInvoice";
import DisplayCard from "components/payments/DisplayCard";
import PayButton from "components/payments/PayButton";
import {
  calcTotalsLineItems,
  convertToCents,
  convertToDollars,
} from "utils/calcTotals";

import RenderLineItems from "components/payments/InvoiceComponents/RenderLineItems";
import { createInvoicePDF } from "../../utils/PDF/InvoiceCreateAndDownload";

export default function InvoiceCard({
  invoice,
  setInvoice,
  amount,
  setAmount,
  amountInDollars,
  setPaymentIntent,
  setPaymentMethod,
  paymentIntent,
  paymentMethod,
  setRefresh,
  tax,
  customer,
  setCustomer,
  businessData,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [tip, setTip] = useState(0); // this is always in cents -- based on the way we designed the tip input.. when manual inputing it only accepts integers

  // if we delete tip then it default to NAN so we need this tip amount variable which will never be Nan
  const tipAmount = isNaN(tip) ? 0 : parseInt(tip); // stays in cents

  // console.log("tip invoiceCard", tip);
  // console.log("amount invoiceCard", amount);

  // state for conditional rendering of css
  const [isHovered, setIsHovered] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [cardComplete, setCardComplete] = useState(false);

  const [shouldSavePaymentMethod, setShouldSavePaymentMethod] = useState(false);

  // console.log("invoice", invoice);

  // const rawTotal = amount + tip; // in cents

  // const totalAmount = parseInt(amount + tip); // this could include a decimal -- so we parse an int

  // // logic for payment request button
  // const [paymentRequest, setPaymentRequest] = useState(null);
  // useEffect(() => {
  //   if (paymentRequest) {
  //     paymentRequest.update({
  //       total: {
  //         label: "Total",
  //         amount: totalAmount,
  //       },
  //     });
  //     console.log("paymentRequest updated");
  //   }

  //   if (stripe && !paymentRequest) {
  //     const country = invoice?.currency === "usd" ? "US" : "CA";
  //     const createPR = async () => {
  //       console.log("trying to create pr");
  //       const pr = stripe.paymentRequest({
  //         country: country,
  //         currency: invoice.currency,
  //         total: {
  //           label: "Total",
  //           amount: amount, // in cents
  //         },
  //         requestPayerName: true,
  //         requestPayerEmail: true,
  //       });

  //       console.log("pr", pr);

  //       // const result = await pr.canMakePayment();

  //       // console.log("result", result);

  //       pr.canMakePayment()
  //         .then((result) => {
  //           if (result.applePay || result.googlePay) {
  //             console.log("can make payment result", result);
  //             setPaymentRequest(pr);
  //           } else {
  //             console.log(
  //               "Cannot make payment, possibly due to lack of browser support or configuration."
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("error in canMakePayment", error);
  //         });

  //       return () => {
  //         setPaymentRequest(null); // Cleanup paymentRequest on component unmount
  //       };
  //     };
  //     createPR();
  //   }
  // }, [stripe, tip]);

  // create a function that creates a payment intent then awaits the response and confirms the payment
  // impressions: this function needs alot of TLC and work to handle errors better and potentially make it faster too..

  // Ideas... we should just have one try catch block that wraps the whole thing, and then if we encounter errors that are critical we will throw an error and catch it at the end of the function and then set the error message and isProcessing to false
  // We should also extract all of the data fetching into external functions to abstract away the ugly fetch logic..
  // I want it to be super modular so that we can read the handleSubmit function in almost natural english and then we can double click to zoom in on functions of interest

  // let's get it all working with the old pattern and then we will refactor it to abstract everything and make it good
  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage(null);
    // checking to ensure we have all the right elements before starting
    if (
      !stripe ||
      !elements ||
      !invoice ||
      !amount ||
      isProcessing ||
      paymentIntent ||
      !cardComplete
    )
      return;
    setIsProcessing(true);

    // check to ensure we have the important data that would otherwise cause an error
    if (
      !invoice.stripeAccountId ||
      !invoice.invoiceId ||
      !invoice.jobId ||
      !invoice.businessId
    ) {
      setErrorMessage(
        "The invoice information is incorrect. Please contact the business owner."
      );
      setIsProcessing(false);
      return;
    }

    // Create stripeCustomer
    let stripeCustomerId = customer?.stripeCustomerId || null;
    // we could make this even nicer by abstracting this away.. we should have a file called utils/invoice.js
    if (shouldSavePaymentMethod && !stripeCustomerId && customer?.customerId) {
      // Only do it if we are saving the payment method, and we don't already have a stripeCustomerId, and we have a customerId

      const result = await createStripeCustomer({
        customerData: customer,
        stripeAccountId: invoice.stripeAccountId,
      });

      if (result.error) {
        // console.log("Error creating customer:", result.error);
        // we don't want to exit the rest of the function if we can't create a customer, we just won't be able to save the payment method on file... and stripeCustomerId will remain null
      }
      if (result.stripeCustomerId) {
        // console.log("created Stripe customer:", result.stripeCustomerId);
        stripeCustomerId = result.stripeCustomerId;
      }
    }

    // Create payment intent
    let paymentIntentCreated;
    try {
      const paymentIntentResponse = await fetch(
        `${node}/intents/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amount, // in cents
            stripeAccountId: invoice.stripeAccountId,
            tip: tipAmount, // in cents and !nan
            tax: tax || 0, // in cents
            email: invoice.customerEmail?.[0],
            description: invoice.lineItems?.[0]?.name,
            invoiceId: invoice.invoiceId,
            jobId: invoice.jobId,
            businessId: invoice.businessId,
            currency: invoice.currency,
            stripeCustomerId: stripeCustomerId, // exists or is null
            shouldSavePaymentMethod: shouldSavePaymentMethod, // will automatically check if we have a stripeCustomerId on the backend before setting "off session" future use
          }),
        }
      );
      // New error handling pattern
      if (!paymentIntentResponse.ok) {
        const errorData = await paymentIntentResponse.json();
        throw new Error(
          errorData.message || "There was an error creating the payment intent."
        );
      }
      // I don't to name it paymentIntent since we have a state variable called that .. but it was working so we can change this later... this is why it would be good to have these in isolated function so that they don't interfere with state names
      const { paymentIntent } = await paymentIntentResponse.json();

      paymentIntentCreated = paymentIntent;
    } catch (error) {
      setErrorMessage(
        error.message || "There was a problem creating the payment intent."
      );
      setIsProcessing(false);
      console.error("Error during payment intent creation:", error);
      return;
    }

    // Confirm payment intent
    let paymentIntentConfirmed;
    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        paymentIntentCreated.client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      if (error) {
        throw error; // Error confirming payment intent
      }
      // If there's no error, assign the result to paymentIntentConfirmed
      paymentIntentConfirmed = paymentIntent;

      // Check if the payment intent has necessary fields
      if (
        !paymentIntentConfirmed ||
        !paymentIntentConfirmed.status ||
        !paymentIntentConfirmed.amount
      ) {
        throw new Error("Payment intent is missing necessary fields");
      }

      // This probably makes the above checks redundant
      // Honestly both of these checks are probably redundant because we have the error check above...
      if (!paymentIntentConfirmed.status === "succeeded") {
        throw new Error(
          `Payment intent status is not succeeded, here is the status: ${paymentIntentConfirmed.status}`
        );
      }

      // console.log(
      //   "payment Intent before sending update request",
      //   paymentIntentConfirmed
      // );

      // We already have this in a webhook, the only thing we are doing differently here is we are adding to the paymentHistory array... in reality we should be doing this in the webhook as well, but using IDempotency keys to ensure that we don't add the same payment twice -- paymentIntent Id should work as that key for all intents and purposes
      try {
        // we don't need to await this... for jobData this is being performed in a transaction for atomicity and idempotency... this is also being ran as a transaction from the stripe webhook
        // we also need to be adding this to customer doc too right? and the customer in the job doc? if we are saving the payment method
        fetch(`${node}/invoices/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "paymentConfirmation",
            paymentIntent: paymentIntentConfirmed,
            tip: tipAmount,
            tax: tax,
            invoice: invoice,
          }),
        });
      } catch (error) {
        console.error("Unexpected error when updating database:", error);
      }
    } catch (error) {
      console.error("Error during payment confirmation:", error);
      setErrorMessage(error.message);
      setIsProcessing(false);

      // console.log("error object before sending", error);
      try {
        // don't need to await... failed payments are only updated from this route because they are less important than successful payments
        fetch(`${node}/invoices/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "paymentError",
            error: error,
            invoice: invoice,
          }),
        });
      } catch (error) {
        console.error("Error during payment confirmation:", error);
      }
    }

    try {
      if (paymentIntentConfirmed) {
        // retrieve payment method from "/retrieve-payment-method" for the last 4 of card to make a clean looking success page
        const paymentMethodResponse = await fetch(
          `${node}/intents/retrieve-payment-method`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentMethodId: paymentIntentConfirmed.payment_method,
              stripeAccountId: invoice.stripeAccountId,
            }),
          }
        );
        if (!paymentMethodResponse.ok) {
          const errorData = await paymentMethodResponse.json();

          if (paymentIntentConfirmed?.status === "succeeded") {
            // console.log(
            //   "payment method not retrieved but payment intent succeeded, so we are salvaging the situation... this is a rare case but needs to be tested for -- we will be unable to update the paymentMethod in customer, invoice ect, but at least everything is marked as paid and we should be able to download the receipt"
            // );
            // this is a case where the payment intent succeeded but the payment method was not retrieved, so we still want to show the success page
            // we need to update the invoice state here... so that we can download the receipt
            setPaymentIntent(paymentIntentConfirmed);
            setInvoice({
              ...invoice,
              datePaid: new Date(),
              paymentIntent: paymentIntentConfirmed,
            });
            setIsProcessing(false);
            return;
          } else {
            // this means that the payment intent was not successful and the payment method was not retrieved
            setErrorMessage(
              errorData.message || "There was an error processing the payment."
            );
          }
          setIsProcessing(false);
          return;
        }

        // I don't like that this is the same variable name as the state variable
        const { paymentMethod: fetchedPaymentMethod } =
          await paymentMethodResponse.json();

        // we don't want to wait for this: fire and forget --> adds the payment intent to the invoice and save the payment intent to the customer firestore doc if shouldSavePaymentMethod is true
        fetch(`${node}/invoices/updatePaymentMethod`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethod: fetchedPaymentMethod,
            invoiceId: invoice.invoiceId,
            businessId: invoice.businessId,
            shouldSavePaymentMethod: shouldSavePaymentMethod,
            stripeCustomerId: stripeCustomerId,
            customerId: customer?.customerId,
            jobId: invoice.jobId,
          }),
        });

        if (
          paymentIntentConfirmed?.status === "succeeded" &&
          fetchedPaymentMethod?.card
        ) {
          // best case scenario: the paymentIntent was successful and the paymentMethod was retrieved
          setPaymentIntent(paymentIntentConfirmed);
          setPaymentMethod(fetchedPaymentMethod);

          // update invoice so we can download the receipt
          // we need to do this in the other case too..
          setInvoice({
            ...invoice,
            datePaid: new Date(),
            paymentIntent: paymentIntentConfirmed,
          });
        }
      }
    } catch (error) {
      // console.log("error retrieving payment method", error);
    }
    setIsProcessing(false);
  }

  const handlePayWithCardOnFile = async () => {
    // console.log("handlePayWithCardOnFile");
    // data validation
    if (
      !customer?.stripePaymentMethod?.id ||
      !customer.stripeCustomerId ||
      !invoice.stripeAccountId
    ) {
      setErrorMessage("Invalid Payment Method or Customer ID");
      return;
    }
    if (isProcessing) return;

    let paymentIntentForUpdate;
    try {
      setIsProcessing(true);

      const response = await fetch(
        `${node}/intents/create-confirm-payment-intent-with-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripePaymentMethodId: customer.stripePaymentMethod.id,
            stripeAccountId: invoice.stripeAccountId,
            stripeCustomerId: customer.stripeCustomerId,
            amount: amount, // should be in cents
            tip: tipAmount, // should be in cents ( might be decimal but this get's truncated on the server via parse int)
            email: customer?.email?.[0] || null, // we always want to send email when we are doing these payments
            description: invoice?.lineItems?.[0]?.name,
            invoiceId: invoice?.invoiceId,
            jobId: invoice?.jobId,
            businessId: invoice.businessId,
            currency: invoice?.currency,
            tax: tax || 0, // should be in cents
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "There was an error creating the payment intent."
        );
        setIsProcessing(false);
        console.error("Error during payment intent creation", errorData);
        return;
      }

      const { paymentIntent: paymentIntentConfirmed } = await response.json();

      // console.log("paymentIntentConfirmed", paymentIntentConfirmed);

      // check if paymentIntent was created and confirmed successfully
      if (
        !paymentIntentConfirmed ||
        !paymentIntentConfirmed.status ||
        !paymentIntentConfirmed.amount
      ) {
        throw new Error("Payment intent is missing necessary fields");
      }

      if (paymentIntentConfirmed.status !== "succeeded") {
        throw new Error("Payment intent status is not succeeded");
      }

      paymentIntentForUpdate = paymentIntentConfirmed;

      setPaymentIntent(paymentIntentConfirmed);
      setPaymentMethod(customer.stripePaymentMethod);
      setInvoice({
        ...invoice,
        datePaid: new Date(),
        paymentIntent: paymentIntentConfirmed,
      });

      setIsProcessing(false);
      // sendReceiptsInBackground();
    } catch (error) {
      console.error("Error processing payment:", error);
      setIsProcessing(false);
      setErrorMessage("Failed to process payment. Please try again.");
      return;
    }

    if (!paymentIntentForUpdate) {
      // we don't need to update invoice if it failed
      return;
    }

    try {
      // we don't need to await this...
      fetch(`${node}/invoices/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "paymentConfirmation",
          paymentIntent: paymentIntentForUpdate,
          tip: tipAmount, // in cents
          tax: tax, // in cents
          invoice: invoice,
        }),
      });
    } catch (error) {
      console.error("Unexpected error when updating database:", error);
    }
  };

  let invoiceNumber = invoice?.invoiceId?.slice(0, 10).toUpperCase();
  if (invoice?.invoiceNumber) {
    invoiceNumber = `#${invoice.invoiceNumber}`;
  }

  const downloadInvoice = async (invoiceId) => {
    if (invoice.pdfUrl) {
      // console.log("invoice has pdfUrl:", invoice.pdfUrl);
      const pdfUrl = invoice.pdfUrl;
      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `invoice${invoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        // console.error("Error downloading invoice:", error);
      }
      return;
    }

    // console.log("invoice does not have pdfUrl so we download from storage");

    // if there was no pdfUrl, we will try to download the invoice from storage
    // console.log("invoiceId: ", invoiceId);
    const storageRef = ref(storage);
    // console.log("storageRef: ", storageRef);
    const invoicesRef = ref(storageRef, `invoices`);
    // console.log("invoiceRef: ", invoicesRef);
    const invoiceRef = ref(invoicesRef, `${invoiceId}.pdf`);
    // console.log("invoiceRef: ", invoiceRef);
    // console.log("invoice file path:", invoiceRef.fullPath);

    try {
      const url = await getDownloadURL(invoiceRef);
      // console.log("url: ", url);
      const response = await fetch(url);
      const blob = await response.blob();

      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `invoice.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  const createAndDownloadInvoicePDF = async () => {
    const invoicePDF = await createInvoicePDF(invoice, businessData);
    invoicePDF.download("Invoice " + invoice.invoiceNumber);
  };

  let dueDate = null;
  if (invoice.dueDate && invoice.dueDate._seconds) {
    const dueDateInSeconds = invoice.dueDate._seconds * 1000; // Convert to milliseconds
    dueDate = new Date(dueDateInSeconds);
    // console.log(dueDate);
  }

  let serviceDate = null;
  if (invoice.serviceDate && invoice.serviceDate._seconds) {
    const serviceDateInSeconds = invoice.serviceDate._seconds * 1000; // Convert to milliseconds
    serviceDate = new Date(serviceDateInSeconds);
    // console.log(serviceDate);
  }

  // we need to alter this so we are doing math based on whether or not the total amount paid(before tip) is greater than the invoice amount (for invoice amount we should really be relying on the line items) --> will allow for partial payments
  if (paymentIntent && paymentIntent.status === "succeeded") {
    return (
      <Success
        paymentIntent={paymentIntent}
        paymentMethod={paymentMethod}
        invoice={invoice}
        // downloadInvoice={downloadInvoice}
        downloadInvoice={createAndDownloadInvoicePDF}
        serviceDate={serviceDate}
        invoiceNumber={invoiceNumber}
        amountInDollars={amountInDollars}
      />
    );
  }

  return (
    <div className="flex flex-col bg-slate-100/90 min-h-screen h-full items-center font-stripe">
      <div className="flex flex-col mt-16 w-96 sm:w-[420px]">
        <h1 className="font-semibold text-2xl text-slate-600 pl-2 ">
          {invoice.businessName}
        </h1>
        <div className="flex flex-col bg-slate-50 w-full rounded-lg shadow-2xl mt-5 relative">
          <div className="absolute top-9 right-7 text-sm text-slate-600">
            <div className="w-16 h-20 border-2 rounded-md shadow-lg ">
              <div className="flex flex-row mt-2  items-center mx-1">
                <div className="w-2 bg-slate-300/90 h-2 rounded-full mr-1"></div>

                <div className="w-6 bg-slate-200 h-1 rounded-md "></div>
              </div>
              <div className="flex flex-col space-y-1.5 mt-3 mx-1">
                <div className="w-3/5 bg-slate-200/60 h-1 rounded-md "></div>
                <div className="w-3/5 bg-slate-200 h-1 rounded-md "></div>
              </div>
              <div className="flex flex-col space-y-1.5 mt-3 mx-1">
                <div className="flex flex-row">
                  <div className="w-2/5 bg-slate-300/80 h-1 rounded-md mr-2 "></div>
                  <div className="w-2/5 bg-slate-300/80 h-1 rounded-md"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-2/5 bg-slate-200 h-1 rounded-md mr-2 "></div>
                  <div className="w-2/5 bg-slate-200 h-1 rounded-md"></div>
                </div>
              </div>
            </div>{" "}
            <div
              className="absolute w-6 h-6 p-1 text-lg text-slate-500 self-end bg-gray-50 z-10 rounded-full shadow-sm cursor-pointer"
              onClick={createAndDownloadInvoicePDF}
              style={{
                bottom: "-10px",
                right: "-5px",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered && (
                <p
                  className={`absolute text-slate-500 text-sm mt-5 z-0 ${
                    isHovered ? "slide-in" : ""
                  }`}
                  style={{
                    left: isHovered ? "-64px" : "-48px",
                    top: "-18px",
                  }}
                >
                  Download
                </p>
              )}
              <HiDownload />{" "}
            </div>
          </div>
          <h1 className="text-slate-700 text-3xl font-bold pt-8 pl-6">
            $
            {amountInDollars.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
          <h1 className="text-slate-500 text-sm pt-1 pl-7">
            {dueDate ? "Due " + format(dueDate, "MMM dd, yyyy") : "Due Date"}
          </h1>
          <h1 className="pl-7 pt-5 text-sm text-slate-700 flex flex-row ">
            <span className="text-slate-500 w-16">To</span>
            <span>{invoice.customerName}</span>
          </h1>
          <h1 className="pl-7 pt-1 text-sm text-slate-700 flex flex-row ">
            <span className="text-slate-500 w-16">From</span>
            <span>{invoice.businessName}</span>
          </h1>
          <h1 className="pl-7 pt-1 text-sm text-slate-700 flex flex-row ">
            <span className="text-slate-500 w-16">Invoice</span>
            <span>{invoiceNumber}</span>
          </h1>
          <div className="pl-7">
            <hr className="text-slate-900 mt-5 mb-5 ml-16" />
          </div>
          <div className="pl-7">
            <h1
              className="text-slate-500 text-sm font-medium mb-8 ml-16 flex flex-row items-center hover:text-slate-800 cursor-pointer"
              onClick={() => setInvoiceDetails(!invoiceDetails)}
            >
              {invoiceDetails ? (
                <>
                  <span className="mr-2">Close invoice details</span>{" "}
                  <IoMdClose />
                </>
              ) : (
                <>
                  <span className="mr-2">View invoice details</span>{" "}
                  <BsChevronDown />
                </>
              )}
            </h1>
          </div>
          <div
            className={`flex flex-col space-y-2  ${
              invoiceDetails ? "open" : "dropdown"
            }`}
          >
            <div
              className={`flex flex-row justify-between mt-5 ${
                invoiceDetails ? "fade-in" : "fade-out"
              } `}
            >
              <h1 className="text-slate-500 text-xl w-1/2 font-medium ml-7">
                $
                {amountInDollars.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
              <div className="flex flex-col text-slate-500 w-3/5 text-sm space-y-1 mr-2">
                <div className="flex flex-row">
                  <h1 className="w-1/2">Invoice</h1>
                  <h1 className="w-1/2">{invoiceNumber}</h1>
                </div>
                <div className="flex flex-row">
                  <h1 className="w-1/2">Service Date</h1>
                  <h1 className="w-1/2">
                    {serviceDate
                      ? format(new Date(serviceDate), "MMM dd, yyyy")
                      : ""}
                  </h1>
                </div>
                <div className="flex flex-row ">
                  <h1 className="w-1/2">Due</h1>
                  <h1 className="w-1/2">Upon receipt</h1>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-row justify-between pt-16 ${
                invoiceDetails ? "fade-in" : "fade-out"
              } `}
            >
              <h1 className="text-slate-500 text-xl w-1/2 font-medium ml-7"></h1>
              <div className="flex flex-col text-slate-500 w-3/5 text-sm space-y-1 mr-2">
                <h1 className="w-full">{invoice.businessName}</h1>
                <h1 className="w-full">
                  {"(" +
                    invoice.businessPhone.substring(2, 5) +
                    ") " +
                    invoice.businessPhone.substring(5, 8) +
                    "-" +
                    invoice.businessPhone.substring(8, 12)}
                </h1>

                <h1 className="w-full"> {invoice.businessEmail}</h1>
                <h1 className="w-full">{invoice.businessAddress}</h1>
              </div>
            </div>
            <RenderLineItems
              invoice={invoice}
              invoiceDetails={invoiceDetails}
            />
            {/* <div className="border-b pb-4 pt-20 mr-2">
              {invoice.lineItems &&
                invoice.lineItems.length > 0 &&
                invoice.lineItems.map((item, key) => {
                  const unitPrice = parseFloat(item.unitPrice);
                  const quantity = parseFloat(item.quantity);
                  const itemSubTotal = unitPrice * quantity;
                  const taxRate = parseFloat(item.taxRate?.rate) || 0;
                  const itemTax = itemSubTotal * (taxRate / 100);

                  // let tax = null;
                  // if (item?.taxRate?.name) {
                  //   let taxRate = parseFloat(item?.taxRate?.rate);
                  //   let lineItemTotal =
                  //     parseFloat(item.quantity) * parseFloat(item.unitPrice);
                  //   tax = lineItemTotal * (taxRate / 100);
                  // }

                  return (
                    <div
                      className={`flex flex-row justify-between pb-2 ${
                        invoiceDetails ? "fade-in" : "fade-out"
                      } `}
                      key={key}
                    >
                      <div className="flex flex-col w-1/2">
                        <h1 className="text-slate-500 text-md  pl-7">
                          {item.name}
                        </h1>
                        {itemTax > 0 && (
                          <h1 className="text-slate-500 text-sm  pl-7">Tax</h1>
                        )}

                        <h1 className="text-slate-400/90 text-sm w-1/2 pl-7 ">
                          Qty {item.quantity}
                        </h1>
                      </div>
                      <div className="flex flex-col text-end">
                        <h1 className="text-slate-500 text-md pr-3">
                          $
                          {item.unitPrice &&
                            parseFloat(item.unitPrice).toFixed(2)}
                        </h1>
                        {itemTax > 0 && (
                          <h1 className="text-slate-500 text-md pr-3">
                            ${itemTax.toFixed(2)}
                          </h1>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div> */}

            <div
              className={`flex flex-row justify-between pt-2 items-center pb-8 ${
                invoiceDetails ? "fade-in-last" : "fade-out"
              } `}
            >
              <div className="flex flex-col w-1/2">
                <h1 className="text-slate-500 text-md  pl-7 font-bold">
                  Amount due
                </h1>
              </div>
              <h1 className="text-slate-500 text-md pr-3 text-lg font-bold mr-2">
                $
                {amountInDollars.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-slate-50 rounded-lg shadow-2xl mt-8 mb-36 relative w-96 sm:w-[420px]">
        <EnterTip
          tip={tip}
          setTip={setTip}
          amount={amount}
          isProcessing={isProcessing}
        />

        {/* {paymentRequest && (
          <div
            className="mx-7
          mt-10"
          >
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </div>
        )} */}

        <PaymentForm
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          invoice={invoice}
          isProcessing={isProcessing}
          shouldSavePaymentMethod={shouldSavePaymentMethod}
          setShouldSavePaymentMethod={setShouldSavePaymentMethod}
          cardComplete={cardComplete}
          setCardComplete={setCardComplete}
          tip={tipAmount}
          amount={amount}
          customer={customer}
          handlePayWithCardOnFile={handlePayWithCardOnFile}
        />
        <div
          className="text-gray-400 text-xs absolute w-full text-center"
          style={{
            bottom: "-70px",
          }}
        >
          Powered by{" "}
          <span className="font-semibold text-gray-500/90">Homebase360</span>{" "}
          and{" "}
          <span className="font-semibold text-gray-500/90 cursor-pointer">
            <Link to="https:/stipe.com/invoices">Stripe</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

const PaymentForm = ({
  handleSubmit,
  errorMessage,
  invoice,
  isProcessing,
  shouldSavePaymentMethod,
  setShouldSavePaymentMethod,
  cardComplete,
  setCardComplete,
  tip,
  amount,
  customer,
  handlePayWithCardOnFile,
}) => {
  const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState(true);

  const savedPaymentMethod = customer?.stripePaymentMethod;

  // tip in this case should never be nan but it could be zero
  const totalPaymentAmount = ((tip + amount) / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }); // both of these are ints in cents.. so this is as close to the direct payment as we can show
  // in the payment intents api we are goind const totalAmount = parseInt(amount + tipAmount);
  // but we are keeping that in cents

  if (savedPaymentMethod) {
    let brand = savedPaymentMethod?.card?.brand || "";
    let formattedBrand =
      brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase();
    return (
      <div>
        <div className="flex flex-col ml-7 mt-10 mb-6 gap-3">
          <div>
            <input
              disabled={isProcessing}
              type="radio"
              id="use-saved-payment-method"
              name="use-saved-payment-method"
              // value="warrant-work"
              checked={useSavedPaymentMethod}
              onChange={() => setUseSavedPaymentMethod(true)}
            />
            <label
              htmlFor="use-saved-payment-method"
              className="ml-2 text-gray-600"
            >
              Card on file
            </label>
            {useSavedPaymentMethod && (
              <div className="flex flex-row items-center gap-2 ml-4 p-2 font-medium text-slate-600">
                <div>{formattedBrand}</div>
                <div>••••{savedPaymentMethod.card.last4}</div>
                <div>
                  Expires {savedPaymentMethod.card.exp_month}/
                  {savedPaymentMethod.card.exp_year}
                </div>
              </div>
            )}
          </div>
          <div>
            <input
              disabled={isProcessing}
              type="radio"
              id="use-other-card"
              name="use-other-card"
              value="use-other-card"
              checked={!useSavedPaymentMethod}
              onChange={() => setUseSavedPaymentMethod(false)}
            />
            <label htmlFor="use-other-card" className="ml-2 text-gray-600">
              Other Card
            </label>
          </div>
        </div>

        {useSavedPaymentMethod ? (
          <div className="mx-7 mb-8">
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
            <div className="flex flex-row justify-between mt-10 items-center">
              <PayButton
                onClick={handlePayWithCardOnFile}
                loading={isProcessing}
              />
              <h1 className="text-lg mt-2 text-slate-700 font-semibold">
                Total ${totalPaymentAmount}
              </h1>
            </div>
          </div>
        ) : (
          <StripeCardForm
            handleSubmit={handleSubmit}
            errorMessage={errorMessage}
            invoice={invoice}
            isProcessing={isProcessing}
            shouldSavePaymentMethod={shouldSavePaymentMethod}
            setShouldSavePaymentMethod={setShouldSavePaymentMethod}
            cardComplete={cardComplete}
            setCardComplete={setCardComplete}
            totalPaymentAmount={totalPaymentAmount}
            customer={customer}
          />
        )}
      </div>
    );
  } else {
    return (
      <StripeCardForm
        handleSubmit={handleSubmit}
        errorMessage={errorMessage}
        invoice={invoice}
        isProcessing={isProcessing}
        shouldSavePaymentMethod={shouldSavePaymentMethod}
        setShouldSavePaymentMethod={setShouldSavePaymentMethod}
        cardComplete={cardComplete}
        setCardComplete={setCardComplete}
        totalPaymentAmount={totalPaymentAmount}
        customer={customer}
      />
    );
  }
};

const StripeCardForm = ({
  handleSubmit,
  errorMessage,
  invoice,
  isProcessing,
  shouldSavePaymentMethod,
  setShouldSavePaymentMethod,
  cardComplete,
  setCardComplete,
  totalPaymentAmount,
  customer,
}) => {
  const options = {
    style: {
      base: {
        iconColor: "#0f172a",
        color: "#1e293b",
        fontWeight: "400",
        fontFamily: "Inter Var, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        // ":focus": {
        //   borderStyle: "solid",
        //   borderWidth: "8px",
        //   borderColor: "#db28ff",
        // },
        ":-webkit-autofill": {
          color: "#1e293b",
        },
        "::placeholder": {
          color: "#94a3b8",
        },
      },
      invalid: {
        iconColor: "#ef4444",
        color: "#ef4444",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="mx-7   rounded-md mb-8 mt-5">
      <CardElement
        className="p-4 shadow-md ring-1 ring-slate-300 rounded-md my-5"
        options={options}
        onChange={(e) => {
          setCardComplete(e.complete);
        }}
      />

      {invoice?.invoiceSettings?.savePaymentMethod && (
        <div className="flex flex-col mt-5">
          <div className="bg-sky-50 rounded-md  p-2">
            <div className="flex flex-row items-start">
              <div
                className=" mr-1.5"
                style={{
                  marginTop: "1px",
                }}
              >
                <IoIosInformationCircleOutline className="text-sky-600 text-xl" />
              </div>
              {customer?.stripePaymentMethod ? (
                <p className="text-sm text-slate-600">
                  Make this your saved card for future payments.
                </p>
              ) : (
                <p className="text-sm text-slate-600">
                  Save card on file for faster checkout next time!{" "}
                  {invoice.businessName} can securely store your card for future
                  payments.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center mt-5 ml-3">
            <CheckboxInvoice
              disabled={isProcessing}
              checked={shouldSavePaymentMethod}
              onCheckedChange={() =>
                setShouldSavePaymentMethod(!shouldSavePaymentMethod)
              }
            />

            <h1 className="text-slate-600 text-sm ml-2">Save card on file</h1>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}

      <div className="flex flex-row justify-between mt-10 items-center">
        <PayButton
          loading={isProcessing}
          disabled={!cardComplete}
          cardComplete={cardComplete}
        />
        {/* <button
          disabled={isProcessing || !cardComplete}
          className={`bg-sky-600/90 w-2/5 gap-2 h-10 items-center flex justify-center rounded-md ${
            cardComplete ? "text-white hover:shadow-lg" : "text-sky-200"
          } font-bold shadow-sm`}
          type="submit"
        >
          {isProcessing ? (
            <BiLoader className="animate-spin-slow text-xl text-white" />
          ) : (
            "Pay"
          )}
        </button> */}
        <h1 className="text-lg mt-2 text-slate-700 font-semibold">
          Total ${totalPaymentAmount}
        </h1>
      </div>
    </form>
  );
};
