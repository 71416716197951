import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  doc,
  updateDoc,
  getDocs,
  onSnapshot,
  startAfter,
  where,
} from "firebase/firestore";

import { node } from "../../constants/constants";
import { uploadFile } from "../../utils/uploadFile";
import * as linkify from "linkifyjs";
import { useSnackBar } from "../../context/SnackBarContext";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
import { formatMessageDate } from "./utils";
import Tooltip from "@mui/material/Tooltip";
import { MdError } from "react-icons/md";
import useOptimisticMessages from "./hooks/useOptimisticMessages";
import DocumentDisplayInChat from "./components/DocumentDisplayInChat";
import { BackLink } from "components/NewButtons/BackLink";
import MessageInput from "./components/MessageInput";

export default function Messages() {
  const location = useLocation();

  const { phone, name } = location?.state || {};
  const { userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  // const [loadingSend, setLoadingSend] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [displayName, setDisplayName] = useState(name);
  const [customerId, setCustomerId] = useState("");
  const mediaUrlRef = useRef(null);
  // this should only be false if custom permissions are set to false for respond_messages
  const notAbleToSend =
    userData.userData?.customPermissions?.respond_messages === false;
  const notAbleToView =
    userData.userData?.customPermissions?.view_messages === false;

  const fetchingMessages = useRef(false);
  const [messages, setMessages] = useState([]);
  // const [input, setInput] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const messageTextAreaRef = useRef(null);
  // useAutosizeTextArea(messageTextAreaRef.current, input);

  const topMessageRef = useRef(); // ref to the top message in messages, not the dom element (used for firestore fetching more messages)
  const bottomMessageRef = useRef(); // ref to the top message in messages, not the dom element (used for firestore fetching more messages)
  const bottomMessageElement = useRef(); // dom element to scroll to when new messages are fetched
  // observe top message dom element to fetch more messages
  const topMessageElement = useCallback(
    (node) => {
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          const fetchMoreMessages = async () => {
            if (fetchingMessages.current) return;
            try {
              fetchingMessages.current = true;
              const messagesRef = collection(
                db,
                "businesses",
                userData?.userData?.businessId,
                "conversations",
                phone,
                "messages"
              );
              const snapshot = await getDocs(
                query(
                  messagesRef,
                  orderBy("createdAt", "desc"),
                  limit(25),
                  startAfter(topMessageRef.current.createdAt)
                )
              );
              const newMessages = snapshot.docs.map((doc) => doc.data());
              if (newMessages.length === 0) {
                setHasMore(false);
                return;
              }
              setMessages((prevMessages) => [
                ...prevMessages,
                ...snapshot.docs.map((doc) => doc.data()),
              ]);
            } catch (e) {
              console.log("error fetching more messages", e);
            } finally {
              fetchingMessages.current = false;
            }
          };
          fetchMoreMessages();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, phone, userData?.userData?.businessId]
  );
  const navigate = useNavigate();

  const [optimisticMessages, addOptimisticMessage, removeOptimisticMessage] =
    useOptimisticMessages({ customerNumber: phone, bottomMessageElement });

  const [initialFetch, setInitialFetch] = useState(true);
  const observer = useRef();

  // keep a ref of bottom/top message if a new message is received from customer scroll to bottom
  useEffect(() => {
    if (!messages || messages?.length === 0) return;
    if (
      bottomMessageRef?.current?._id !== messages[0]._id &&
      messages?.[0]?._id
    ) {
      bottomMessageRef.current = messages[0];
      if (messages[0].to !== phone) {
        bottomMessageElement.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
    if (topMessageRef?.current?._id !== messages[messages.length - 1]._id)
      topMessageRef.current = messages[messages.length - 1];
  }, [messages, bottomMessageElement, phone]);

  // get initial messages, listen for new messages or changes, and update conversation doc
  useEffect(() => {
    const messagesRef = collection(
      db,
      "businesses",
      userData?.userData?.businessId,
      "conversations",
      phone,
      "messages"
    );
    const conversationRef = doc(
      db,
      "businesses",
      userData.userData.businessId,
      "conversations",
      phone
    );
    const updateConversationDoc = async () => {
      try {
        await updateDoc(conversationRef, {
          read: true,
        });
      } catch (error) {
        console.log(
          "Error updating document or its a new conversation: ",
          error
        );
      }
    };

    // get initial messages, listen for new messages or changes, and update conversation doc
    const unsubscribe = onSnapshot(
      query(messagesRef, orderBy("createdAt", "desc"), limit(25)),
      (snapshot) => {
        setMessages((prevMessages) => {
          let newMessages = [...prevMessages];
          // initial fetch, or new conversation
          if (prevMessages.length === 0) {
            const initialMessages = snapshot
              .docChanges()
              .map((change) => change.doc.data());
            if (initialMessages.length === 1)
              // Remove optimistic message for first message (listener wouldnt be setup yet)
              removeOptimisticMessage({
                text: initialMessages[0].text,
                imageUrl: initialMessages[0].imageUrl,
              });
            setInitialFetch(false);
            updateConversationDoc();
            return initialMessages;
          }
          // listen for new messages or changes
          snapshot.docChanges().forEach((change) => {
            const newMsg = change.doc.data();
            if (
              change.type === "added" &&
              newMsg?.createdAt?.seconds &&
              bottomMessageRef.current?.createdAt?.seconds &&
              newMsg.createdAt.seconds >
                bottomMessageRef.current.createdAt.seconds
            ) {
              newMessages = [newMsg, ...newMessages];
              removeOptimisticMessage({
                text: newMsg.text,
                imageUrl: newMsg.imageUrl,
              });
            } else if (change.type === "modified") {
              newMessages = newMessages.map((msg) =>
                msg._id === newMsg._id ? newMsg : msg
              );
            }
          });
          updateConversationDoc();
          return newMessages;
        });
      }
    );
    return () => unsubscribe();
  }, [userData?.userData?.businessId, phone, removeOptimisticMessage]);

  // update display name if necessary, save id of customer
  useEffect(() => {
    const getCustomerUpdateConversation = async () => {
      const querySnapshot = await getDocs(
        query(
          collection(
            db,
            "businesses",
            userData?.userData?.businessId,
            "customers"
          ),
          where("phone.mobile", "==", phone)
        )
      );
      const customerSnapshot = querySnapshot.docs[0];
      if (customerSnapshot?.data()?.displayName === name) {
        setCustomerId(customerSnapshot.data().customerId);
        return; // no need to update if already correct
      }
      const newCustomerName = customerSnapshot?.data()?.displayName || ""; // default to unknown if no display name if number deleted from customer
      const conversationRef = doc(
        db,
        "businesses",
        userData?.userData?.businessId,
        "conversations",
        phone
      );
      await updateDoc(conversationRef, {
        displayName: newCustomerName,
      });
      setDisplayName(newCustomerName);
    };
    getCustomerUpdateConversation();
  }, [phone, userData?.userData?.businessId, name, setDisplayName]);

  const handleSendMessage = async (e, input) => {
    e.preventDefault();
    if (!input && !selectedFile) {
      return;
    }
    if (notAbleToSend) {
      openSnackBar(
        "You do not have permission to send messages. Please contact your administrator."
      );
      return;
    }
    if (fetchingMessages.current) {
      console.log("fetchingMessages.current returning");
      return;
    }

    const to = phone;
    const from = userData?.bizData?.twilioNumber;
    const message = input;
    const bizId = userData?.userData?.businessId;
    const bizName = userData?.bizData?.companyName;
    const customerName = displayName ? displayName : "";

    addOptimisticMessage({ text: message, imageUrl: mediaUrlRef.current }); // add promise or mediaUrl to optimistic messages
    fileInputRef.current.value = "";
    let mediaUrl = mediaUrlRef.current;
    setPreviewUrl("");
    // if the media url is still uploading wait before sending the message
    if (mediaUrlRef.current?.then) {
      mediaUrlRef.current = await mediaUrlRef.current;
      mediaUrl = mediaUrlRef.current; // save a reference to the resolved url
    }
    mediaUrlRef.current = null;

    try {
      const response = await fetch(`${node}/messages/twilioSend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to,
          from,
          message,
          bizId,
          bizName,
          customerName,
          mediaUrl,
        }),
      });

      const { message: responseMessage, error } = await response.json();

      if (responseMessage) {
        setMessageSent(true);
      }
      if (error) {
        openSnackBar(
          error?.raw?.errors?.[0]?.title
            ? "There was an error sending your message: " +
                error.raw.errors[0].title
            : "There was an error sending your message, please try again.",
          false,
          true
        );
        removeOptimisticMessage({
          text: message,
          imageUrl: mediaUrl,
        });
      }
    } catch (error) {
      openSnackBar(
        "There was an error sending your message, please try again.",
        false,
        true
      );
      console.log("error:", error.message);
      removeOptimisticMessage({
        text: message,
        imageUrl: mediaUrl,
      });
    }
  };

  const handleImageClick = (imageUrl) => {
    console.log("image clicked:", imageUrl);
    setEnlargedImage(imageUrl);
    setIsModalOpen(true);
  };
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const [selectedFile, setSelectedFile] = useState(null); // holds a reference to the pre uploaded file
  const [previewUrl, setPreviewUrl] = useState("");

  async function handleFileChange(e) {
    if (e.target.files[0].size > 1000000) {
      openSnackBar(
        "Your file size exceeds the 1MB limit. Please try again.",
        false,
        true
      );
      return;
    }
    setSelectedFile(e.target.files[0]);
    // Create a preview URL and update the state
    const previewUrl = URL.createObjectURL(e.target.files[0]);
    setPreviewUrl(previewUrl);
    // upload the file here, before sending the message
    if (e.target.files[0]) {
      try {
        const mediaUrlPromise = uploadFile(e.target.files[0], "message-images");
        mediaUrlRef.current = mediaUrlPromise; // provide a ref to the promise, we can use this in sendMessage to await the upload.
        mediaUrlRef.current = await mediaUrlPromise; // update the value once it resolves
      } catch (error) {
        console.error("Failed to upload file:", error);
        openSnackBar("Failed to upload file, please try again.", false, true);
      }
    }
  }

  function LinkifyAndFormatText({ text }) {
    // Convert newlines to <br /> tags
    let formattedText = text.replace(/\n/g, "<br />");

    // Convert URLs to clickable links
    formattedText = linkify.find(formattedText).reduce((current, next) => {
      return current
        .split(next.value)
        .join(
          `<a href="${next.href}" target="_blank" rel="noopener noreferrer">${next.value}</a>`
        );
    }, formattedText);

    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
  }

  function isLinkAnImage(url) {
    const ImageType = new Set([
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
      "svg",
      "ico",
      "apng",
    ]);
    try {
      // Decode the URL to handle encoded characters like %2F
      const decodedUrl = decodeURIComponent(url);

      // Extract the file name from the URL
      const fileName = decodedUrl.split("/").pop()?.split("?")[0];

      // Get the file extension from the file name
      const fileExtension = fileName?.split(".").pop()?.toLowerCase();

      // return fileExtension ? fileExtension.toLowerCase() : null;

      return ImageType.has(fileExtension);
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  }

  function isLinkIframeable(url) {
    const IframeableType = new Set(["pdf"]);
    try {
      // Decode the URL to handle encoded characters like %2F
      const decodedUrl = decodeURIComponent(url);

      // Extract the file name from the URL
      const fileName = decodedUrl.split("/").pop()?.split("?")[0];

      // Get the file extension from the file name
      const fileExtension = fileName?.split(".").pop()?.toLowerCase();

      return IframeableType.has(fileExtension);
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  }

  return (
    <>
      <div
        className={`bg-gray-50 relative ${
          bannerVisible ? "h-full-messages-minus-banner" : "h-full-messages"
        } overflow-y-auto text-gray-800`}
      >
        <div className="absolute ml-0 top-2">
          <BackLink toLink={"/messages"} toName={"Messages"} state={null} />
        </div>
        <div
          className="flex flex-col justify-start items-center w-full "
          style={{
            height: "calc(100% - 4rem)",
          }}
        >
          <div
            onClick={() =>
              customerId &&
              navigate(`/customers/${customerId}`, {
                state: { customerId: customerId },
              })
            }
            className={`flex flex-row justify-between w-4/5 max-w-3xl mt-6${
              customerId ? " cursor-pointer" : ""
            }`}
          >
            <h1 className="text-2xl font-extrabold text-gray-900">
              {displayName ? displayName : ""}
            </h1>
            <h1 className="text-2xl font-extrabold text-gray-900">
              {"(" +
                phone.substring(2, 5) +
                ") " +
                phone.substring(5, 8) +
                "-" +
                phone.substring(8, 12)}
            </h1>
          </div>
          <div className="flex flex-col-reverse pb-8 pt-10 bg-white rounded-t-md mt-2  h-full mb-10 w-4/5 max-w-3xl  overflow-y-auto overflow-x-hidden relative messages-container shadow-md">
            {initialFetch ? (
              <>
                <div className="flex mx-5 flex-row mt-8 justify-start">
                  <div className="flex w-3/5 gap-4 items-start flex-row">
                    <div className="animate-pulse bg-gray-300 w-10 h-10 p-2 rounded-full shrink-0 items-center justify-center flex"></div>
                    <div className="flex flex-col w-full items-start justify-center rounded-b-lg p-2 px-4 bg-gray-200 rounded-r-lg">
                      <div className="animate-pulse bg-gray-300 w-full h-4 rounded mt-2"></div>

                      <div className="animate-pulse bg-gray-300 w-5/6 h-4 rounded mt-2 mb-2"></div>
                    </div>
                  </div>
                </div>
                <div className="flex mx-5 flex-row mt-8 justify-end">
                  <div className="flex w-3/5 gap-4 items-start  flex-row-reverse">
                    <div className="animate-pulse bg-gray-300 w-10 h-10 p-2 rounded-full shrink-0 items-center justify-center flex"></div>
                    <div className="flex flex-col w-full items-start justify-center rounded-b-lg p-2 px-4 bg-gray-200 rounded-l-lg">
                      <div className="animate-pulse bg-gray-300 w-full h-4 rounded mt-2"></div>

                      <div className="animate-pulse bg-gray-300 w-5/6 h-4 rounded mt-2 mb-2"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {(messages && messages.length) ||
                (optimisticMessages && optimisticMessages.length) ? (
                  [...optimisticMessages, ...messages].map((message, i) => {
                    return (
                      <div
                        className={`flex mx-5 flex-row mt-8 ${
                          message.user._id === userData.userData.businessId
                            ? "justify-end"
                            : "justify-start"
                        }`}
                        key={message._id}
                        ref={
                          i === 0
                            ? bottomMessageElement
                            : i === messages.length - 1
                            ? topMessageElement
                            : undefined
                        }
                      >
                        {" "}
                        <div
                          className={`flex w-3/5 gap-4 items-start ${
                            message.user._id === userData.userData.businessId
                              ? "flex-row-reverse"
                              : "flex-row"
                          }`}
                        >
                          <p className="text-xs text-yellow-400 w-10 bg-gray-800 h-10 p-2 rounded-full shrink-0 items-center justify-center flex">
                            {message.user.initials}
                          </p>

                          <div
                            className={`flex flex-col items-center justify-center rounded-b-lg p-2 ${
                              notAbleToView && "blur"
                            } ${
                              message.user._id === userData.userData.businessId
                                ? "bg-gray-700 text-gray-50  rounded-l-lg"
                                : "bg-gray-200 rounded-r-lg"
                            }`}
                          >
                            <LinkifyAndFormatText text={message.text} />
                            {message.imageUrl &&
                              (message.imageUrl.then ? (
                                <LoadingSpinner /> // if the imageUrl is a promise then it's an optimistic message where the file hasn't been uploaded yet, show a loading spinner until it resolves
                              ) : isLinkAnImage(message.imageUrl) ? (
                                <img
                                  src={message.imageUrl}
                                  onClick={() =>
                                    handleImageClick(message.imageUrl)
                                  }
                                  alt="Message link"
                                  className="w-1/2 cursor-pointer mb-2"
                                />
                              ) : isLinkIframeable(message.imageUrl) ? (
                                <iframe
                                  src={message.imageUrl}
                                  width="100%"
                                  height="200px" // you can adjust the size as needed
                                  className="mt-2"
                                  title="Message link"
                                />
                              ) : (
                                <DocumentDisplayInChat uri={message.imageUrl} />
                              ))}
                            {/* delivery info  */}
                            <div className="flex flex-row w-full justify-end gap-1">
                              <p className="text-xs text-gray-500 hover:cursor-default">
                                {
                                  message.deliveredAt
                                    ? formatMessageDate(
                                        message.deliveredAt.toDate() // show delivered at date
                                      )
                                    : message.createdAt
                                    ? formatMessageDate(
                                        message.createdAt.toDate() // show created at data
                                      )
                                    : formatMessageDate(new Date()) // if the message doesn't have a "createdAt" it must be an optimistic message that was just sent
                                }
                              </p>
                              {message.to === userData.bizData.telnyxNumber ? (
                                <></> // message is to user dont show status
                              ) : message.delivered ? ( // sent and delivered
                                <>
                                  <p className="text-gray-500 text-xs">✓✓</p>
                                </>
                              ) : message.delivered === undefined &&
                                message.createdAt ? ( // sent but not delivered
                                <p className="text-gray-500 text-xs">✓</p>
                              ) : message.delivered === undefined ? ( // message is still being sent
                                <></>
                              ) : (
                                // delivery failed (error)
                                <Tooltip
                                  title={
                                    message.error
                                      ? "Delivery of this message failed. " +
                                        message.error
                                      : "Delivery of this message failed. Please try again."
                                  }
                                >
                                  <div>
                                    <MdError className="text-red-500 ml-1" />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-row justify-center items-center h-full">
                    <p className="text-gray-500">No messages</p>
                  </div>
                )}
              </>
            )}
            <MessageInput
              handleSendMessage={handleSendMessage}
              handleFileChange={handleFileChange}
              handleIconClick={handleIconClick}
              fileInputRef={fileInputRef}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
              mediaUrlRef={mediaUrlRef}
              messageTextAreaRef={messageTextAreaRef}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-1 bg-black bg-opacity-75"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="fixed z-10 inset-0 overflow-y-auto"
            onClick={() => setIsModalOpen(false)}
          >
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-white rounded-lg max-w-lg mx-auto relative overflow-hidden">
                <img
                  src={enlargedImage}
                  className="w-full"
                  alt="Enlarged Preview"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
